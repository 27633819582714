import { ProjectedPlayerSlatByDateConstants } from "../../constants";


const initialState = { loadingProjectedPlayerSlat: null, projectedPlayerSlatByDateList: null } || {};

export function projectedPlayerSlatByDateReducer(state = initialState, action) {
    switch (action.type) {
        case ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_REQUEST:
            return {
                loadingProjectedPlayerSlat: true
            };
        case ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_SUCCESS:
            return {
                loadingProjectedPlayerSlat: false,
                projectedPlayerSlatByDateList: action.projectedPlayerSlatByDateList
            };
        case ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_FAILURE:
            return {
                loadingProjectedPlayerSlat: false,
                error: action.error
            };
        default:
            return state;
    }
}