import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from '../../assets/img/ball-loader.gif'
import Norecord from '../../assets/img/no-record.png'
import { seasonActions } from "../../actions";
import Slider from "react-slick";
import CloseButton from '../../assets/img/times-solid.svg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import {DatePicker} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const monthOptions = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sept" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" }
];

class Teams extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      loading: null,
      seasonList: [],
      selectedYear: "",
      selectedMonth: "",
      // selectedDate:  moment().subtract(1, 'days').format('DD/MM/YYYY'),
      selectedDate: new Date().setDate(new Date().getDate()),
      yearOptions: [],
      monthOptions: [],
      dateOptions: [],
      defaultDate: "",
      selectedDataForPopUp: [],
      modal: false,
    };

    this.getSeasonList = this.getSeasonList.bind(this);
    this.setSelectedYear = this.setSelectedYear.bind(this);
    this.setSelectedMonth = this.setSelectedMonth.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(item) {
    this.setState({
      modal: !this.state.modal,
      selectedDataForPopUp: item
    });
  }

  onCloseModal() {
    this.setState({
      modal: false,
      selectedDataForPopUp: []
    });
  }

  setSelectedYear(e) {
    this.setState({
      selectedYear: e.value,
      monthOptions: monthOptions
      // }, () => {
      //     this.getFilteredData();
    })
  }

  setSelectedMonth(e) {
    this.setState({
      selectedMonth: e.value
    }, () => {
      this.getDateOptions();
      //this.getFilteredData();
    })
  }

  setSelectedDate(value) {
    // var ss = moment(value).format('YYYY-MM-DD');
    this.setState({
      selectedDate: value
    }, () => {
      this.getSeasonList();
    })
  }

  getYearOpions() {
    if (this.state.seasonList !== null && this.state.seasonList !== undefined) {
      var yearOptions = [];
      var yearList = [];
      var seasonList = this.state.seasonList;

      seasonList.map((data) => {
        var date = new Date(data.start_time);
        var year = date.getFullYear();
        if (!yearList.includes(year)) {
          yearList.push(year);
        }
      });

      yearList.map((data) => {
        yearOptions.push({ value: data, label: data });
      });

      this.setState({
        yearOptions: yearOptions,
        //selectedYear: currentYear
      });
    }
  }

  getDateOptions() {
    var year = this.state.selectedYear;
    var month = this.state.selectedMonth;
    var days = new Date(year, month, 0).getDate();
    var dateOptions = [];

    for (var i = 1; i <= days; i++) {
      dateOptions.push({ value: i, label: i })
    }

    this.setState({
      dateOptions: dateOptions
    })
  }

  getFilteredData() {
    var year = this.state.selectedYear;
    var month = this.state.selectedMonth;
    var date = this.state.selectedDate;
    var filteredData = [];
    var seasonList = this.state.seasonList;

    if (year !== "" && month !== "" && date !== "") {
      filteredData = seasonList.filter((data) => {
        var dateObj = new Date(data.start_time);
        var gameYear = dateObj.getFullYear();
        var gameMonth = parseInt(dateObj.getMonth()) + 1;
        var gameDate = dateObj.getDate();

        if (gameDate === date && gameMonth === month && gameYear === year) {
          return data;
        }
      });
    }

    this.setState({
      seasonList: filteredData
    }, () => {
      // console.log(this.state.seasonList);
    })
  }

  getSeasonList() {
    this.props.dispatch(seasonActions.getSeasonList(this.state.selectedDate));
  }

  componentDidMount() {
    this.getSeasonList();
  }


  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    // console.log("Teams nextProps ", nextProps);
    if (nextProps.loading !== prevProps.loading) {
      this.setState({
        loading: nextProps.loading
      });
    }

    if (nextProps.seasonList !== prevProps.seasonList) {
      if (nextProps.seasonList !== undefined) {
        this.setState({
          seasonList: nextProps.seasonList,
        }, () => {
          this.getYearOpions();
        });
      }
    }
  }

  render() {
  
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: true,
      infinite: this.state.seasonList.length>7 ? true : false,
      slidesToShow: 7,
      slidesToScroll: 1,
      
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: this.state.seasonList.length>3 ? true : false,
          dots: true,
          speed: 3000,
          autoplay: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          speed: 3000,
          autoplay: true,
          infinite: this.state.seasonList.length>2 ? true : false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 3000,
          autoplay: true,
          infinite: this.state.seasonList.length>1 ? true : false,
          dots: true
        }
      }
    ]
    };

    const selectTheme = (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#191C24',
      },
    });
    const closeBtn = (
      <button className="close" onClick={() => this.onCloseModal()} type="button">
        <img width="15" src={CloseButton} />
      </button>
    );
    return (
      <>
        <Modal isOpen={this.state.modal} fade={false} size="xl" centered toggle={this.toggle} className={this.props.className}>
          {/* <ModalHeader className="bg-naviblue text-yellow" close={closeBtn} toggle={this.toggle}>Game Info</ModalHeader> */}
          <ModalHeader className="bg-naviblue text-yellow team-modal-header" close={closeBtn} toggle={this.toggle}>
            <div class="row">
              <div className="col-12 col-sm-10 col-md-8 col-lg-3 text-center m-auto">
                <div className="d-flex justify-content-between GameCardMatch">
                  <div class="GameCardMatchup_article__Fsvx9 flex-column">
                    <div className="d-flex justify-content-center align-items-center d-flex text-white">{this.state.selectedDataForPopUp.HomeTeam}</div>
                    <div className="d-flex justify-content-center align-items-center text-white">{this.state.selectedDataForPopUp.HomeTeamScore}</div>
                  </div>
                  <div className="GameCardMatchup_statusWrapper__TDbQz my-0 align-items-center">
                    <div className="GameCardMatchupStatusText_gcsText__PcQUX">VS</div>
                  </div>
                  <div class="GameCardMatchup_article__Fsvx9 flex-column">
                    <div className="d-flex justify-content-center align-items-center d-flex text-white">{this.state.selectedDataForPopUp.AwayTeam}</div>
                    <div className="d-flex justify-content-center align-items-center text-white">{this.state.selectedDataForPopUp.AwayTeamScore}</div>
                  </div>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="bg-black">
            <div className="card bg-naviblue quarters mb-3">
              <div className="">
                <table class="table table-bordered mb-0 team-modal-table1">
                  <thead className="thead-light">
                    <tr>
                      <th>Over Under</th>
                      <th>{this.state.selectedDataForPopUp.AwayTeam} Money Line</th>
                      <th>{this.state.selectedDataForPopUp.HomeTeam} Money Line</th>
                      <th>Point Spread {this.state.selectedDataForPopUp.AwayTeam} Money Line</th>
                      <th>Point Spread {this.state.selectedDataForPopUp.HomeTeam} Money Line</th>
                      <th>Over Payout</th>
                      <th>Under Payout</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Over Under">{this.state.selectedDataForPopUp.OverUnder}</td>
                      <td data-label={`${this.state.selectedDataForPopUp.AwayTeam} Money Line`}>{this.state.selectedDataForPopUp.AwayTeamMoneyLine}</td>
                      <td data-label={`${this.state.selectedDataForPopUp.HomeTeam} Money Line`}>{this.state.selectedDataForPopUp.HomeTeamMoneyLine}</td>
                      <td data-label={`${this.state.selectedDataForPopUp.AwayTeam}`}>{this.state.selectedDataForPopUp.PointSpreadAwayTeamMoneyLine}</td>
                      <td data-label={`${this.state.selectedDataForPopUp.HomeTeam} Money Line`}>{this.state.selectedDataForPopUp.PointSpreadHomeTeamMoneyLine}</td>
                      <td data-label="Over Payout">{this.state.selectedDataForPopUp.OverPayout}</td>
                      <td data-label="Under Payout">{this.state.selectedDataForPopUp.UnderPayout}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div className="col-sm-12 mb-3 mt-4">
              <h5 className="text-yellow">Quarters</h5>
            </div>
            <div className="row">
              {(this.state.selectedDataForPopUp.Quarters !== undefined
                && this.state.selectedDataForPopUp.Quarters !== null)
                ? this.state.selectedDataForPopUp.Quarters.map((qurter, index) => (

                  <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                    <div className="card quarters bg-naviblue mb-2">
                      <div className="card-body">
                        <div className="card-heading mb-2 text-white">
                          Quarter {qurter.Name}
                        </div>
                        <table class="table table-bordered mb-0">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="" width="50%">{this.state.selectedDataForPopUp.AwayTeam}</th>
                              <td className="" width="50%">{qurter.AwayScore}</td>
                            </tr>
                            <tr>
                              <th className="" width="50%">{this.state.selectedDataForPopUp.HomeTeam}</th>
                              <td className="" width="50%">{qurter.HomeScore}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )) : <div></div>}
            </div>

          </ModalBody>
          <ModalFooter className="bg-naviblue">
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div class="d-flex justify-content-between align-items-center">
          <ul class="nav nav-pills daytime-filter">
            <li class="nav-item d-none">
              <a class="nav-link" aria-current="page" href="#">
                <Select
                  // defaultValue = { this.state.selectedYear }
                  // value = { this.state.selectedYear }
                  className="w-100"
                  placeholder="Year"
                  theme={selectTheme}
                  onChange={(e) => this.setSelectedYear(e)}
                  options={this.state.yearOptions}
                />
              </a>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" href="#">
                <Select
                  // defaultValue = "Select Category"
                  className="selectpicker monthly w-100"
                  placeholder="Month"
                  onChange={(e) => this.setSelectedMonth(e)}
                  options={this.state.monthOptions}
                />
              </a>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" href="#">
                <Select
                  defaultValue="Select Category"
                  className="weekly w-100"
                  placeholder="Weekly"
                  //onChange = {setSelectedOption}
                  options={this.state.categories}
                /></a>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link" href="#">
                <Select
                  className="selectpicker daily w-100"
                  placeholder="Date"
                  onChange={(e) => this.setSelectedDate(e)}
                  options={this.state.dateOptions}
                /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <DatePicker
                  selected={this.state.selectedDate}
                  onChange={this.setSelectedDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </a>
            </li>

          </ul>
          <span class="ms-auto d-none">
            <i class="fas fa-filter"></i>Teams
          </span>
        </div>
        {
          !this.state.loading && this.state.seasonList.length == 0
          ?  <div className="text-center norecord py-3 bg-naviblue card mt-3" style={{ height: "92px" }}>
              <h4 className="mb-0">No Record Found</h4>
              {/* <span><img width="209" src={Norecord} /></span> */}
            </div>
          :
          this.state.loading !== "" && !this.state.loading 
          ? 
        
          <Slider {...settings} className="filtering">
            {(this.state.seasonList !== undefined && this.state.seasonList !== null && this.state.seasonList.length > 0) ? this.state.seasonList.map((item, index) => (
              <div key={index}>
                <div class="basketmatch gradient1" color="danger" onClick={() => this.toggle(item)}>
                  <span class="GameSummary_date___X9wS"><span>{moment(item.DateTime).format('MMM D, YYYY')} </span></span>
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="GameCardMatchup_article__Fsvx9">
                      <div class="MatchupCardTeamLogo d-none">
                        <span class="TeamLogo_block"><img width="50" src="img/tm1.svg" /></span>
                      </div>
                      <div class="d-flex justify-content-center align-items-center d-flex">{item.HomeTeam}</div>
                      <div class="d-flex justify-content-center align-items-center">{item.HomeTeamScore == null ? 0 : item.HomeTeamScore}</div>
                    </div>
                    <div class="GameCardMatchup_statusWrapper__TDbQz d-none">
                      <div class="GameCardMatchup_gameCardMatchupStatusWrapper__8rQ8v ">
                        <div class="GameCardMatchupStatusText_gcs__2yfjE">
                          <span class="GameCardMatchupStatusText_gcsPre__rnEtg">PRESEASON</span>
                          <div class="GameCardMatchupStatusText_gcsText__PcQUX">VS</div>
                        </div>
                        <p class="GameCardMatchup_showBroadcasters__B7lu2 d-none">{item.Channel}</p>
                      </div>
                    </div>
                    <div class="GameCardMatchup_article__Fsvx9">
                      <div class="MatchupCardTeamLogo d-none">
                        <span class="TeamLogo_block"><img width="50" src="img/tm2.svg" /></span>
                      </div>
                      <span class="d-flex justify-content-center align-items-center d-flex">{item.AwayTeam}</span>
                      <span class="d-flex justify-content-center align-items-center">{item.AwayTeamScore == null ? 0 : item.AwayTeamScore}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
              : <div className="text-center norecord 12121212">
                <span><img width="209" src={Norecord} /></span>
              </div>
            }
          </Slider>
          :
          <div className="placeholder-loader">
            <div class="row">
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              <div class="col">
                <div class="placeholder wave">
                  {/* <div class="line"><br /></div>
                    <div class="line"><br /></div> */}
                  {/* <div class="circle"><br /></div> */}
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div>
              {/* <div class="col">
                <div class="placeholder wave">
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                  <div class="circle"><br /></div>
                  <div class="line"><br /></div>
                  <div class="line"><br /></div>
                </div>
              </div> */}
            </div>
          </div>

        }

      </>
    );
  }
}

function mapStateToProps(state) {

  const { loading, seasonList } = state.rootReducer.seasons;
  // console.log('mapStateToProps ', state.rootReducer);
  return {
    loading,
    seasonList
  };
}

export default connect(mapStateToProps)(Teams);
