import React, { Component, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CloseButton from '../../assets/img/times-solid.svg'
import { connect } from "react-redux";
import MultiRangeSlider from "multi-range-slider-react";

// import MultiRangeSlider from "react-js-multi-range-sliders";
import { playerListByTeamActions, dfsSlateByDateActions, boxScoreByDateActions, projectedPlayerSlatByDateActions } from "../../actions";
import Norecord from '../../assets/img/no-record.png'
import Loader from '../../assets/img/ball-loader.gif'
import moment from 'moment';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Navigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class PlayersList extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      modalToggle: false,
      loading: true,
      playerList: [],
      loadingdfsSlate: true,
      dfsSlateByDateList: [],
      loadingBoxScore: true,
      boxScoreByDateList: [],
      loadingProjectedPlayerSlat: true,
      projectedPlayerSlatByDateList: [],
      playerDataForShow: [],
      dataForCSVDownload: [],
      actualLoading: true,
      playerCombineMainData: [],

      selectedFanDuelPosition: "All",
      selectedDraftKingsPosition: "All",
      selectedYahooPosition: 'All',
      selectedOpponentRank: 'all',
      selectedOpponentPositionRank: 'all',

      opponentRankMin: 0,
      opponentRankMax: 0,
      opponentPositionRankMin: 0,
      opponentPositionRankMax: 0,
      fanDuelSalaryMin: 0,
      fanDuelSalaryMax: 60000,
      selectedFanDuelSalaryMinValue: 0,
      selectedFanDuelSalaryMaxValue: 20000,
      draftKingSalaryMin: 0,
      draftKingSalaryMax: 50000,
      selectedDraftKingSalaryMinValue: 0,
      selectedDraftKingSalaryMaxValue: 20000,
      yahooSalaryMin: 0,
      yahooSalaryMax: 200,
      selectedYahooSalaryMinValue: 0,
      selectedYahooSalaryMaxValue: 100,

      fantasyPointsFanDuelMin: 0,
      fantasyPointsFanDuelMax: 100,
      selectedFantasyPointsFanDuelMinValue: 0,
      selectedFantasyPointsFanDuelMaxValue: 100,
      fantasyPointsDraftKingsMin: 0,
      fantasyPointsDraftKingsMax: 100,
      selectedFantasyPointsDraftKingsMinValue: 0,
      selectedFantasyPointsDraftKingsMaxValue: 100,
      fantasyPointsYahooMin: 0,
      fantasyPointsYahooMax: 100,
      selectedFantasyPointsYahooMinValue: 0,
      selectedFantasyPointsYahooMaxValue: 100,

      isSortedDesc: false,
      sortType: "asc",
      sortBy: 'FantasyPointsFanDuel',
      callModalButton: false,

      searchText: '',

      user: JSON.parse(localStorage.getItem('user')),
      currentPlan : JSON.parse(localStorage.getItem('isSubscriberUser')), 
      firstTimeLoginUserPlan: JSON.parse(localStorage.getItem('firstTimeLoginUserPlan')),
      // selectedDate:  new Date(JSON.parse(localStorage.getItem('playerListSelectedDate'))),
      // selectedDate: new Date().setDate(new Date().getDate()),
      selectedDate: JSON.parse(localStorage.getItem('playerListSelectedDate'))!=null 
                    ? new Date(JSON.parse(localStorage.getItem('playerListSelectedDate')))
                    : new Date().setDate(new Date().getDate())
                    
    };

    this.csvLink = React.createRef();

    this.modalToggle = this.modalToggle.bind(this);
    this.getBoxScoreByDateList = this.getBoxScoreByDateList.bind(this);
    this.getProjectedPlayerSlatByDateList = this.getProjectedPlayerSlatByDateList.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.setSelectedFanDuelSalary = this.setSelectedFanDuelSalary.bind(this);
    this.setSelectedDraftKingSalary = this.setSelectedDraftKingSalary.bind(this);
    this.setSelectedYahooSalary = this.setSelectedYahooSalary.bind(this);
    this.setSelectedFantasyPointsFanDuel = this.setSelectedFantasyPointsFanDuel.bind(this);
    this.setSelectedFantasyPointsDraftKings = this.setSelectedFantasyPointsDraftKings.bind(this);
    this.setSelectedFantasyPointsYahoo = this.setSelectedFantasyPointsYahoo.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.onSearchHandle = this.onSearchHandle.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
  }

  
  
  modalToggle() {

    this.setState({
      modalToggle: !this.state.modalToggle,
      callModalButton: false
    }, () => {
      // console.log("modalToggleCall");
      this.scrollInPlayerListAfterModelClose();
    });
  }

  backButtonClick(){
    localStorage.setItem("playerListSelectedDate", JSON.stringify(this.state.selectedDate));
    console.log('previewPage',this.state.selectedDate)
    window.location.href = "/nba";
  }
  onCloseModal() {
    // console.log(1121);
    this.setState({
      modalToggle: false,
      callModalButton: false
    }, () => {
      this.scrollInPlayerListAfterModelClose();
    });
  }

  scrollInPlayerListAfterModelClose() {
    window.scrollTo(0, 500);
  }

  resetModal() {
    this.setState({
      modalToggle: false,
      selectedFanDuelSalaryMinValue: 0,
      selectedFanDuelSalaryMaxValue: 20000,
      selectedDraftKingSalaryMinValue: 0,
      selectedDraftKingSalaryMaxValue: 20000,
      selectedYahooSalaryMinValue: 0,
      selectedYahooSalaryMaxValue: 100,
      selectedFantasyPointsFanDuelMinValue: 0,
      selectedFantasyPointsFanDuelMaxValue: 100,
      selectedFantasyPointsDraftKingsMinValue: 0,
      selectedFantasyPointsDraftKingsMaxValue: 100,
      selectedFantasyPointsYahooMinValue: 0,
      selectedFantasyPointsYahooMaxValue: 100,
      opponentPositionRankMin: 0,
      opponentPositionRankMax: 0,
      opponentRankMin: 0,
      opponentRankMax: 0,
      selectedFanDuelPosition: "All",
      selectedDraftKingsPosition: "All",
      selectedYahooPosition: 'All',
      selectedOpponentRank: 'all',
      selectedOpponentPositionRank: 'all',
      playerDataForShow: this.state.playerCombineMainData,
      dataForCSVDownload: this.state.playerCombineMainData,
      isSortedDesc: false,
      sortType: "asc",
      sortBy: 'FantasyPointsFanDuel',
      // callModalButton: true,
    }, () => {
      this.dataShowWithoutModal();
    });
  }


  setSelectedDraftKingsPosition(e) {
    this.setState({
      selectedDraftKingsPosition: e.target.value,
    }, () => {
    });
  }
  setSelectedYahooPosition(e) {
    this.setState({
      selectedYahooPosition: e.target.value,
    }, () => {
    });
  }
  setSelectedFanDuelPosition(e) {
    this.setState({
      selectedFanDuelPosition: e.target.value,
    }, () => {
    });
  }

  setSelectedOpponentRank(e) {
    // console.log('OpponentRank ', e);
    this.setState({
      selectedOpponentRank: e.target.value,
    }, () => {
      this.applyOppnentRankFilter();
    });
  }
  setSelectedOpponentPositionRank(e) {
    this.setState({
      selectedOpponentPositionRank: e.target.value,
    }, () => {
      this.applyOppnentPositionRankFilter();
    });
  }
  setSelectedFanDuelSalary (e)  {
    // console.log('FanDuelSalary ', e);
    this.setState({
      selectedFanDuelSalaryMinValue: e.minValue,
      selectedFanDuelSalaryMaxValue: e.maxValue
    });
  }



  setSelectedDraftKingSalary(e) {
    // console.log('DraftKingSalary ', e);
    this.setState({
      selectedDraftKingSalaryMinValue: e.minValue,
      selectedDraftKingSalaryMaxValue: e.maxValue
    });
  }

  setSelectedYahooSalary(e) {
    // console.log('YahooSalary ', e);
    this.setState({
      selectedYahooSalaryMinValue: e.minValue,
      selectedYahooSalaryMaxValue: e.maxValue
    });
  }

  setSelectedFantasyPointsFanDuel(e) {
    this.setState({
      selectedFantasyPointsFanDuelMinValue: e.minValue,
      selectedFantasyPointsFanDuelMaxValue: e.maxValue
    });
  }

  setSelectedFantasyPointsDraftKings(e) {
    this.setState({
      selectedFantasyPointsDraftKingsMinValue: e.minValue,
      selectedFantasyPointsDraftKingsMaxValue: e.maxValue
    });
  }

  setSelectedFantasyPointsYahoo(e) {
    this.setState({
      selectedFantasyPointsYahooMinValue: e.minValue,
      selectedFantasyPointsYahooMaxValue: e.maxValue
    });
  }

  handleSorting(type, e) {
    var sortType = "";
    if (this.state.sortType === "asc") {
      sortType = "desc";
    }
    else {
      sortType = "asc";
    }

    this.setState({
      sortType: sortType,
      sortBy: type,
    }, () => {
      this.dataShowWithoutModal();
    });

  }

  handleFilterSubmit() {
    this.dataShow();
  }


  dataShow() {
    const dataFiltered = this.getDataUsingFilter();
    this.setState({
      playerDataForShow: dataFiltered,
      actualLoading: false,
      callModalButton: true
    }, () => {

      // this.getDataForCSVDownload();
      this.modalToggle();
    });
    // console.log(this.state);
    // console.log(dataFiltered);
  }

  dataShowWithoutModal() {
    const dataFiltered = this.getDataUsingFilter();
    this.setState({
      playerDataForShow: dataFiltered,
      actualLoading: false
    }, () => {
      // this.modalToggle();
    });
    // console.log(this.state);
  }
  getDataUsingFilter() {
    // console.log('getDataUsingFilter ', this.state);
    var playerCombineMainData = this.state.playerCombineMainData;
    let dataFiltered = playerCombineMainData.filter(regData =>
      (regData.FanDuelSalary >= this.state.selectedFanDuelSalaryMinValue && regData.FanDuelSalary <= this.state.selectedFanDuelSalaryMaxValue)
      &&
      (regData.DraftKingsSalary >= this.state.selectedDraftKingSalaryMinValue && regData.DraftKingsSalary <= this.state.selectedDraftKingSalaryMaxValue)
      &&
      (regData.YahooSalary >= this.state.selectedYahooSalaryMinValue && regData.YahooSalary <= this.state.selectedYahooSalaryMaxValue)
      &&
      (regData.FantasyPointsFanDuel >= this.state.selectedFantasyPointsFanDuelMinValue && regData.FantasyPointsFanDuel <= this.state.selectedFantasyPointsFanDuelMaxValue)
      &&
      (regData.FantasyPointsDraftKings >= this.state.selectedFantasyPointsDraftKingsMinValue && regData.FantasyPointsDraftKings <= this.state.selectedFantasyPointsDraftKingsMaxValue)
      &&
      (regData.FantasyPointsYahoo >= this.state.selectedFantasyPointsYahooMinValue && regData.FantasyPointsYahoo <= this.state.selectedFantasyPointsYahooMaxValue)
    );
    if (this.state.sortBy !== "") {
      var test = this.state.sortBy;
      if (this.state.sortType === "asc") {
        // console.log('asc');

        dataFiltered.sort(function (a, b) {
          return b[test] - a[test];
        });
      }
      else {
        // console.log('desc');
        dataFiltered.sort(function (a, b) {
          return a[test] - b[test];
        });
      }
    }
    if(this.state.searchText!== ""){
      dataFiltered = dataFiltered.filter(o => o.OperatorPlayerName.toLowerCase().includes(this.state.searchText.toLowerCase()));
    }

    if (this.state.selectedOpponentRank !== 'all') {
      dataFiltered = dataFiltered.filter(regData =>
        (regData.OpponentRank >= this.state.opponentRankMin && regData.OpponentRank <= this.state.opponentRankMax)
      );
    }
    if (this.state.selectedOpponentPositionRank !== 'all') {
      dataFiltered = dataFiltered.filter(regData =>
        (regData.OpponentPositionRank >= this.state.opponentPositionRankMin && regData.OpponentPositionRank <= this.state.opponentPositionRankMax)
      );
    }
    if (this.state.selectedFanDuelPosition !== 'All') {
      dataFiltered = dataFiltered.filter(regData =>
        (regData.FanDuelPosition === this.state.selectedFanDuelPosition)
      );
    }
    if (this.state.selectedDraftKingsPosition !== 'All') {
      dataFiltered = dataFiltered.filter(regData =>
        (regData.DraftKingsPosition === this.state.selectedDraftKingsPosition)
      );
    }
    if (this.state.selectedYahooPosition !== 'All') {
      dataFiltered = dataFiltered.filter(regData =>
        (regData.YahooPosition === this.state.selectedYahooPosition)
      );
    }
    return dataFiltered;

  }

  applyOppnentRankFilter() {
    var min = "";
    var max = "";
    if (this.state.selectedOpponentRank === "all") {
      min = 0; max = 100;
    }
    else if (this.state.selectedOpponentRank === "1_to_10") {
      min = 1; max = 10
    }
    else if (this.state.selectedOpponentRank === "11_to_20") {
      min = 11; max = 20
    }
    else if (this.state.selectedOpponentRank === "21_to_30") {
      min = 21; max = 30
    }
    this.setState({
      opponentRankMin: min,
      opponentRankMax: max,
    }, () => { });
  }

  applyOppnentPositionRankFilter() {
    var min = "";
    var max = "";
    if (this.state.selectedOpponentPositionRank === "all") {
      min = 0; max = 100;
    }
    else if (this.state.selectedOpponentPositionRank === "1_to_10") {
      min = 1; max = 10
    }
    else if (this.state.selectedOpponentPositionRank === "11_to_20") {
      min = 11; max = 20
    }
    else if (this.state.selectedOpponentPositionRank === "21_to_30") {
      min = 21; max = 30
    }
    this.setState({
      opponentPositionRankMin: min,
      opponentPositionRankMax: max,
    }, () => { });
  }

  getPlayerList() {
    // this.props.dispatch(playerListByTeamActions.getPlayerListByTeam("ATL"));
  }


  getBoxScoreByDateList() {
    // this.props.dispatch(boxScoreByDateActions.getAllBoxScoreByDate());
  }

  setSelectedDate(value) {
    localStorage.removeItem('playerListSelectedDate');
    localStorage.setItem("StatusOfSelectedDateOfPlayerList", true);
    this.setState({
      selectedDate: value,
      actualLoading: true
    }, () => {
      this.getProjectedPlayerSlatByDateList();
    })
  }

  getProjectedPlayerSlatByDateList() {
    this.props.dispatch(projectedPlayerSlatByDateActions.getAllProjectedPlayerSlatByDate(this.state.selectedDate));
  }

  getAllPlayerData() {
    var dfsData = [];
        this.state.projectedPlayerSlatByDateList.map(proData => {
          var obj = {
            'OperatorStartTime': proData.Day,
            'OperatorPlayerName': proData.Name,
            'Team': proData.Team,
          };
          obj.Opponent = (proData.Opponent !== null && proData.Opponent !== "") ? proData.Opponent : "-";

          obj.InjuryStatus = (proData.InjuryStatus !== null && proData.InjuryStatus !== "") ? proData.InjuryStatus : "-";
          obj.InjuryNotes = (proData.InjuryNotes !== null && proData.InjuryNotes !== "") ? proData.InjuryNotes : "-";
          obj.HomeOrAway = (proData.HomeOrAway !== null && proData.HomeOrAway !== "") ? proData.HomeOrAway : "-";
          obj.LineupStatus = (proData.LineupStatus !== null && proData.LineupStatus !== "") ? proData.LineupStatus : "-";
          obj.FanDuelSalary = (proData.FanDuelSalary !== null && proData.FanDuelSalary !== "") ? proData.FanDuelSalary : "-";
          obj.DraftKingsSalary = (proData.DraftKingsSalary !== null && proData.DraftKingsSalary !== "") ? proData.DraftKingsSalary : "-";
          obj.YahooSalary = (proData.YahooSalary !== null && proData.YahooSalary !== "") ? proData.YahooSalary : "-";
          
              obj.FanDuelPosition = proData.FanDuelPosition;
              obj.DraftKingsPosition = proData.DraftKingsPosition;
              obj.OpponentRank = proData.OpponentRank;
              obj.OpponentPositionRank = proData.OpponentPositionRank;
           
            obj.YahooPosition = proData.YahooPosition;
            
            obj.FantasyPointsFanDuel = proData.FantasyPointsFanDuel;
            obj.FantasyPointsDraftKings = proData.FantasyPointsDraftKings;
            obj.FantasyPointsYahoo = proData.FantasyPointsYahoo;
            
            obj.Minutes = proData.Minutes;
            obj.FieldGoalsMade = proData.FieldGoalsMade;
            obj.FieldGoalsAttempted = proData.FieldGoalsAttempted;
            obj.FieldGoalsPercentage = proData.FieldGoalsPercentage;
            obj.ThreePointersMade = proData.ThreePointersMade;
            obj.ThreePointersAttempted = proData.ThreePointersAttempted;
            obj.ThreePointersPercentage = proData.ThreePointersPercentage;
            obj.FreeThrowsAttempted = proData.FreeThrowsAttempted;
            obj.FreeThrowsPercentage = proData.FreeThrowsPercentage;
            obj.Rebounds = proData.Rebounds;
            obj.Assists = proData.Assists;
            obj.Steals = proData.Steals;
            obj.BlockedShots = proData.BlockedShots;
            obj.Turnovers = proData.Turnovers;
            obj.Points = proData.Points;
            obj.TrueShootingAttempts = proData.TrueShootingAttempts;
            obj.TrueShootingPercentage = proData.TrueShootingPercentage;
            obj.PlusMinus = proData.PlusMinus;
            obj.DoubleDoubles = proData.DoubleDoubles;
            obj.TripleDoubles = proData.TripleDoubles;
            var boomPotential = false;
            if((proData.OpponentRank >= 21 && proData.OpponentRank <= 30) && (proData.OpponentPositionRank >= 21 && proData.OpponentPositionRank <= 30))
            {
              boomPotential = true;
            }
            
            var bustPotential = false;
            if((proData.OpponentRank >= 1 && proData.OpponentRank <= 10) && (proData.OpponentPositionRank >= 1 && proData.OpponentPositionRank <= 10))
            {
              bustPotential = true;
            }
            obj.boomPotential = boomPotential;
            obj.bustPotential = bustPotential;
          dfsData.push(obj);
        });
   
    this.setState({
      playerCombineMainData: dfsData,
      playerDataForShow: dfsData,
      actualLoading: false
    }, () => {
      this.dataShowWithoutModal();
    });
    // console.log('playerDataForShow  ', dfsData);

  }
  

  componentDidMount() {
    // console.log("2323 ", new Date());
    // // localStorage.removeItem('playerListSelectedDate')
    // if(JSON.parse(localStorage.getItem('playerListSelectedDate'))!=null)
    // {
    //   console.log(localStorage.getItem('playerListSelectedDate'));
    // }
    // else {
    //   console.log(12121);
    // }
    localStorage.removeItem('playerListSelectedDate');
    this.getBoxScoreByDateList();
    this.getProjectedPlayerSlatByDateList();
  }

 

  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    // console.log("nextProps playerList  ", nextProps);

    if (nextProps.loadingBoxScore !== prevProps.loadingBoxScore) {
      this.setState({
        loadingBoxScore: nextProps.loadingBoxScore
      });
    }

    if (nextProps.boxScoreByDateList !== prevProps.boxScoreByDateList) {
      if (nextProps.boxScoreByDateList !== undefined && nextProps.boxScoreByDateList !== null) {
        this.setState({
          boxScoreByDateList: nextProps.boxScoreByDateList
        }, () => {
          this.getAllPlayerData();
        });
      }
    }

    if (nextProps.loadingProjectedPlayerSlat !== prevProps.loadingProjectedPlayerSlat) {
      this.setState({
        loadingProjectedPlayerSlat: nextProps.loadingProjectedPlayerSlat
      });
    }

    if (nextProps.projectedPlayerSlatByDateList !== prevProps.projectedPlayerSlatByDateList) {
      if (nextProps.projectedPlayerSlatByDateList !== undefined && nextProps.projectedPlayerSlatByDateList !== null) {
        this.setState({
          projectedPlayerSlatByDateList: nextProps.projectedPlayerSlatByDateList
        }, () => {
          this.getAllPlayerData();
        });
      }
    }
  }

  myClassName(index) {
    return "testtest_" + index;
  }

  getDataForCSVDownload(){
    var dfsData = [];
    this.state.playerDataForShow.map(proData => {
      var obj = {
        'Player Name': proData.OperatorPlayerName,
        'Team': proData.Team,
      };
      obj.FanDuelPosition = proData.FanDuelPosition;
      obj.DraftKingsPosition = proData.DraftKingsPosition;
      obj.YahooPosition = proData.YahooPosition;
      obj.OpponentRank = proData.OpponentRank;
      obj.OpponentPositionRank = proData.OpponentPositionRank;
      obj.Opponent = (proData.Opponent !== null && proData.Opponent !== "") ? proData.Opponent : "-";
      obj.HomeOrAway = (proData.HomeOrAway !== null && proData.HomeOrAway !== "") ? proData.HomeOrAway : "-";
      obj.FanDuelSalary = (proData.FanDuelSalary !== null && proData.FanDuelSalary !== "") ? proData.FanDuelSalary : "-";
      obj.DraftKingsSalary = (proData.DraftKingsSalary !== null && proData.DraftKingsSalary !== "") ? proData.DraftKingsSalary : "-";
      obj.YahooSalary = (proData.YahooSalary !== null && proData.YahooSalary !== "") ? proData.YahooSalary : "-";
      obj.LineupStatus = (proData.LineupStatus !== null && proData.LineupStatus !== "") ? proData.LineupStatus : "-";
      obj.Minutes = proData.Minutes;
      obj.FieldGoalsMade = proData.FieldGoalsMade;
      obj.FieldGoalsAttempted = proData.FieldGoalsAttempted;
      obj.FantasyPointsFanDuel = proData.FantasyPointsFanDuel;
      obj.FantasyPointsDraftKings = proData.FantasyPointsDraftKings;
      obj.FantasyPointsYahoo = proData.FantasyPointsYahoo;
      obj.FieldGoalsPercentage = proData.FieldGoalsPercentage;
      obj.ThreePointersMade = proData.ThreePointersMade;
      obj.ThreePointersAttempted = proData.ThreePointersAttempted;
      obj.ThreePointersPercentage = proData.ThreePointersPercentage;
      obj.FreeThrowsAttempted = proData.FreeThrowsAttempted;
      obj.FreeThrowsPercentage = proData.FreeThrowsPercentage;
      obj.Rebounds = proData.Rebounds;
      obj.Assists = proData.Assists;
      obj.Steals = proData.Steals;
      obj.BlockedShots = proData.BlockedShots;
      obj.Turnovers = proData.Turnovers;
      obj.Points = proData.Points;
      obj.TrueShootingAttempts = proData.TrueShootingAttempts;
      obj.TrueShootingPercentage = proData.TrueShootingPercentage;
      obj.InjuryStatus = (proData.InjuryStatus !== null && proData.InjuryStatus !== "") ? proData.InjuryStatus : "-";
      obj.InjuryNotes = (proData.InjuryNotes !== null && proData.InjuryNotes !== "") ? proData.InjuryNotes : "-";
      obj.Date = moment(proData.OperatorStartTime).format('MMM D, YYYY')
      dfsData.push(obj);
    });
    this.setState({
      dataForCSVDownload: dfsData
    }, () => {
      this.csvLink.current.link.click()
    });
  }

  downloadCSV() {
    this.getDataForCSVDownload();  
  }

  onSearchHandle(e) {
    this.setState({
      searchText: e.target.value
    }, () => {
      this.dataShowWithoutModal();
    });
  }

  render() {

    
    const closeBtn = (
      <button className="close" onClick={() => this.onCloseModal()} type="button">
        <img width="15" src={CloseButton} />
      </button>
    );

    if(this.state.user === null){
        // console.log(423234);
        return <Navigate to="/login" />;
    }
    if(this.state.currentPlan == false)
    {
      return <Navigate to="/subscription-plan" />;
    }
    
    return (
      <div className="player-table playerlistpreview mt-0">
       <CSVLink
          data={this.state.dataForCSVDownload}
          filename='player-data.csv'
          className="hidden"
          ref={this.csvLink}
          target="_blank" 
       />
        <div>
          {/* <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel} Demo</Button> */}
          {/* <Modal size="lg" isOpen={this.state.modal} className={this.props.className} toggle={() => this.modalToggle()}> */}
          <Modal isOpen={this.state.modalToggle} size="lg" centered toggle={() => this.modalToggle()} >
            <ModalHeader close={closeBtn} className="bg-naviblue text-yellow" toggle={() => this.modalToggle()}>Advanced Filters</ModalHeader>

            {/* <ModalHeader close={closeBtn} className="bg-naviblue text-yellow" toggle={() => this.modalToggle()}>Advance Filter</ModalHeader> */}
            <ModalBody className="bg-black playerlistmodal py-2">
              <div className="card p-2 mb-2">
                <div className="row">
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label className="mb-2">Fan Duel Position</label>
                      <div className="select-arrow">
                        <select className="form-select"
                          onChange={(e) => this.setSelectedFanDuelPosition(e)}
                          value={this.state.selectedFanDuelPosition}
                        >
                          <option value="All">All</option>
                          <option value="PG">PG</option>
                          <option value="SG">SG</option>
                          <option value="SF">SF</option>
                          <option value="PF">PF</option>
                          <option value="C">C</option>
                          <option value="F(SF/PF)">F(SF/PF)</option>
                        </select>
                        {/* <i class="fas fa-chevron-down"></i> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label className="mb-2">Draft Kings Position</label>
                      <div className="select-arrow">
                        <select className="form-select"
                          onChange={(e) => this.setSelectedDraftKingsPosition(e)}
                          value={this.state.selectedDraftKingsPosition}
                        >
                          <option value="All">All</option>
                          <option value="PG">PG</option>
                          <option value="SG">SG</option>
                          <option value="SF">SF</option>
                          <option value="PF">PF</option>
                          <option value="C">C</option>
                          <option value="F(SF/PF)">F(SF/PF)</option>
                        </select>
                        {/* <i class="fas fa-chevron-down"></i> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label className="mb-2">Yahoo Position</label>
                      <div className="select-arrow">
                        <select className="form-select"
                          onChange={(e) => this.setSelectedYahooPosition(e)}
                          value={this.state.selectedYahooPosition}
                        >
                          <option value="All">All</option>
                          <option value="PG">PG</option>
                          <option value="SG">SG</option>
                          <option value="SF">SF</option>
                          <option value="PF">PF</option>
                          <option value="C">C</option>
                          <option value="F(SF/PF)">F(SF/PF)</option>
                        </select>
                        {/* <i class="fas fa-chevron-down"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-2 mb-2">
                <div className="row">
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label className="mb-2">Opponent Rank</label>
                      <div className="select-arrow">
                        <select className="form-select"
                          onChange={(e) => this.setSelectedOpponentRank(e)}
                          // options={this.state.opponentRankOptions}
                          value={this.state.selectedOpponentRank}
                        >
                          <option value="all">All</option>
                          <option value="1_to_10">1 To 10</option>
                          <option value="11_to_20">11 To 20</option>
                          <option value="21_to_30">21 To 30</option>
                        </select>

                        {/* <i class="fas fa-chevron-down"></i> */}
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label className="mb-2">Opponent Position Rank</label>
                      <div className="select-arrow">
                        <select className="form-select"
                          onChange={(e) => this.setSelectedOpponentPositionRank(e)}
                          // options={this.state.opponentRankOptions}
                          value={this.state.selectedOpponentPositionRank}
                        >
                          <option value="all">All</option>
                          <option value="1_to_10">1 To 10</option>
                          <option value="11_to_20">11 To 20</option>
                          <option value="21_to_30">21 To 30</option>
                        </select>
                        {/* <i class="fas fa-chevron-down"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-2 mb-2">
                <div className="row">
                  <div className="col-sm-12">
                    <h6 className="mb-3 text-white">Salary</h6>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <label>Fan Duel Salary</label>
                    <div className="">
                      <div className="d-flex justify-content-between mb-1">
                        <span className="text-yellow">{this.state.selectedFanDuelSalaryMinValue}</span>
                        <span className="text-yellow">{this.state.selectedFanDuelSalaryMaxValue}</span>
                      </div>
                      <MultiRangeSlider
                        min={this.state.fanDuelSalaryMin}
                        max={this.state.fanDuelSalaryMax}
                        minValue={this.state.selectedFanDuelSalaryMinValue}
                        maxValue={this.state.selectedFanDuelSalaryMaxValue}
                        step={100}
                        onChange={ this.setSelectedFanDuelSalary  }
                      />


                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <label>Draft Kings Salary</label>
                    <div className="">
                      <div className="d-flex justify-content-between mb-1">
                        <span className="text-yellow">{this.state.selectedDraftKingSalaryMinValue}</span>
                        <span className="text-yellow">{this.state.selectedDraftKingSalaryMaxValue}</span>
                      </div>
                      <MultiRangeSlider
                        min={this.state.draftKingSalaryMin}
                        max={this.state.draftKingSalaryMax}
                        minValue={this.state.selectedDraftKingSalaryMinValue}
                        maxValue={this.state.selectedDraftKingSalaryMaxValue}
                        step={100}
                        onChange={ this.setSelectedDraftKingSalary  }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <label>Yahoo Salary</label>
                    <div className="">
                      <div className="d-flex justify-content-between mb-1">
                        <span className="text-yellow">{this.state.selectedYahooSalaryMinValue}</span>
                        <span className="text-yellow">{this.state.selectedYahooSalaryMaxValue}</span>
                      </div>
                      <MultiRangeSlider
                        min={this.state.yahooSalaryMin}
                        max={this.state.yahooSalaryMax}
                        minValue={this.state.selectedYahooSalaryMinValue}
                        maxValue={this.state.selectedYahooSalaryMaxValue}
                        // onChange={(e) => this.setSelectedYahooSalary(e)}
                        onChange={ this.setSelectedYahooSalary  }
                        step={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card p-2">
                <div className="row">
                  <div className="col-sm-12">
                    <h6 className="mb-3 text-white mt-2">Fantasy Point Projection</h6>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label>Fan Duel</label>
                      <div className="">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-yellow">{this.state.selectedFantasyPointsFanDuelMinValue}</span>
                          <span className="text-yellow">{this.state.selectedFantasyPointsFanDuelMaxValue}</span>
                        </div>
                        <MultiRangeSlider
                          min={this.state.fantasyPointsFanDuelMin}
                          max={this.state.fantasyPointsFanDuelMax}
                          minValue={this.state.selectedFantasyPointsFanDuelMinValue}
                          maxValue={this.state.selectedFantasyPointsFanDuelMaxValue}
                          // onChange={(e) => this.setSelectedFantasyPointsFanDuel(e)}
                          onChange={ this.setSelectedFantasyPointsFanDuel  }
                          step={5}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label>Draft Kings</label>
                      <div className="">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-yellow">{this.state.selectedFantasyPointsDraftKingsMinValue}</span>
                          <span className="text-yellow">{this.state.selectedFantasyPointsDraftKingsMaxValue}</span>
                        </div>
                        <MultiRangeSlider
                          min={this.state.fantasyPointsDraftKingsMin}
                          max={this.state.fantasyPointsDraftKingsMax}
                          minValue={this.state.selectedFantasyPointsDraftKingsMinValue}
                          maxValue={this.state.selectedFantasyPointsDraftKingsMaxValue}
                          // onChange={(e) => this.setSelectedFantasyPointsDraftKings(e)}
                          onChange={ this.setSelectedFantasyPointsDraftKings  }
                          step={5}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3 mb-sm-0">
                    <div className="form-group">
                      <label>Yahoo</label>
                      <div className="">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-yellow">{this.state.selectedFantasyPointsYahooMinValue}</span>
                          <span className="text-yellow">{this.state.selectedFantasyPointsYahooMaxValue}</span>
                        </div>
                        <MultiRangeSlider
                          min={this.state.fantasyPointsYahooMin}
                          max={this.state.fantasyPointsYahooMax}
                          minValue={this.state.selectedFantasyPointsYahooMinValue}
                          maxValue={this.state.selectedFantasyPointsYahooMaxValue}
                          // onChange={(e) => this.setSelectedFantasyPointsYahoo(e)}
                          onChange={ this.setSelectedFantasyPointsYahoo  }
                          step={5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="bg-naviblue">
              <Button color="secondary" onClick={this.handleFilterSubmit}>Apply</Button>
              <Button color="secondary " onClick={this.resetModal}>Reset</Button>
            </ModalFooter>
          </Modal>
        </div>
        
        <div className="card bg-naviblue scrollTopFeature rounded-0">
          <div className="card-body pb-0">
            <div className="mt-2 d-md-flex align-items-center justify-content-between mb-3 flex-wrap">
              {/* <h5 className=" d-inline-block mb-3 text-white">Assisted by Machine Learning - AI</h5> */}
                <div className="d-xl-flex">
                  <button className="btn-yellow me-5 px-3 mb-3 mb-xl-0 text-black" onClick={this.backButtonClick}><i class="fas fa-chevron-left me-1"></i> Back</button>
                  <span className="me-3 h6 h5-lg-5 cursor-pointer align-items-center d-flex text-white mb-0"><span class="d-inline-block me-2 bg-blue" style={{width: "30px", height:"10px"}}></span> Projections Assisted By Machine Learning - AI</span>
                  <span className="me-3 h6 h5-lg-5 cursor-pointer align-items-center d-flex text-white my-2 my-xl-0"><span class="d-inline-block me-2 bg-success" style={{width: "30px", height:"10px"}}></span>Boom Potential</span>
                  <span className="me-3 h6 h5-lg-5 cursor-pointer align-items-center d-flex text-white mb-0"><span class="d-inline-block me-2 bg-danger" style={{width: "30px", height:"10px"}}></span>Bust Potential</span>
                </div> 
                <div className="d-md-flex align-items-center mt-4 mt-md-0 flex-wrap">
                <div className="me-2 mb-4 mb-md-0">
                    <DatePicker
                    selected={this.state.selectedDate}
                    onChange={this.setSelectedDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    />
                    {/* <input type="Date" className="form-control" /> */}
                  </div> 
                  <div className="searchbox position-relative d-inline-block">
                    <input className="form-control" onChange={this.onSearchHandle}/>
                     <button className="searchbtn"> <i class="fas fa-search text-black"></i> </button>
                  </div>
                  <span title="Export CSV" onClick={this.downloadCSV} className="fas fa-download fa-lg cursor-pointer text-yellow float-end mx-2 mt-2 mt-md-0"></span> 
                  <span onClick={this.modalToggle} title="Apply Filter" className="fas fa-filter fa-lg cursor-pointer text-yellow float-end me-2 mt-2 mt-md-0"></span>
                </div>  
            </div>
            <div className="fixTableHead scrollcolor">
              {
                (this.state.actualLoading)
                  ? <div className="text-center img-loader d-flex align-items-center justify-content-center h-100"> <img src={Loader} width="" height="" /> </div>
                  : (this.state.playerDataForShow !== undefined && this.state.playerDataForShow !== null && this.state.playerDataForShow.length === 0)
                    ? <div className="text-center norecord d-flex align-items-center justify-content-center h-100">
                      <span><img width="209" src={Norecord} /></span>
                    </div>
                    :
                    <table className="table table-striped table-bordered table-fixed table-wrap" id="testtest">
                      <thead>
                        <tr className="border-0" ref={this.myRef}>
                          <th scope="col" className="fixed-side">PLAYER</th>
                          <th scope="col">TEAM</th>
                          <th scope="col" title="Fan Duel Position">FDP	</th>
                          <th scope="col" title="Draft Kings Position">DKP</th>

                          <th scope="col" title="Yahoo Position">YP</th>
                          <th scope="col" title="Opponent Rank" onClick={(e) => this.handleSorting('OpponentRank', e)}>OR
                            {this.state.sortBy === "OpponentRank" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" onClick={(e) => this.handleSorting('OpponentPositionRank', e)} title="Opponent Position Rank">OPR
                          {this.state.sortBy === "OpponentPositionRank" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" title="Opponent">OP</th>
                          <th scope="col" title="HomeOrAway">HW</th>
                          <th scope="col" onClick={(e) => this.handleSorting('FanDuelSalary', e)} title="Fan Duel Salary">FDS
                          {this.state.sortBy === "FanDuelSalary" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" onClick={(e) => this.handleSorting('DraftKingsSalary', e)} title="Draft Kings Salary">DKS
                          {this.state.sortBy === "DraftKingsSalary" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" onClick={(e) => this.handleSorting('YahooSalary', e)} title="Yahoo Salary">YS
                          {this.state.sortBy === "YahooSalary" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>

                           <th scope="col" title="Lineup Status">LS</th>

                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Minutes', e)} title="Minutes">M
                          {this.state.sortBy === "Minutes" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>

                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FieldGoalsMade', e)} title="Field Goals Made">FGM
                          {this.state.sortBy === "FieldGoalsMade" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FieldGoalsAttempted', e)} title="Field Goals Attempted">FGA
                          {this.state.sortBy === "FieldGoalsAttempted" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                         
                         
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FantasyPointsFanDuel', e)}title="Fantasy Points FanDuel">FPF
                          {this.state.sortBy === "FantasyPointsFanDuel" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FantasyPointsDraftKings', e)} title="Fantasy Points Draft Kings">FPDK
                          {this.state.sortBy === "FantasyPointsDraftKings" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>

                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FantasyPointsYahoo', e)} title="Fantasy Points Yahoo">FPY
                          {this.state.sortBy === "FantasyPointsYahoo" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          {/* <th scope="col">Lineup Status</th> */}

                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FieldGoalsPercentage', e)} title="Field Goals Percentage">FGP
                          {this.state.sortBy === "FieldGoalsPercentage" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('ThreePointersMade', e)} title="Three Pointers Made">TPM
                          {this.state.sortBy === "ThreePointersMade" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('ThreePointersAttempted', e)} title="Three Pointers Attempted">TPA
                          {this.state.sortBy === "ThreePointersAttempted" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('ThreePointersPercentage', e)} title="Three Pointers Percentage">TPP
                          {this.state.sortBy === "ThreePointersPercentage" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FreeThrowsAttempted', e)} title="Free Throws Attempted">FTA
                          {this.state.sortBy === "FreeThrowsAttempted" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('FreeThrowsPercentage', e)} title="Free Throws Percentage">FTP
                          {this.state.sortBy === "FreeThrowsPercentage" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Rebounds', e)} title="Rebounds" >Rb
                          {this.state.sortBy === "Rebounds" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Assists', e)} title="Assists" >AST
                          {this.state.sortBy === "Assists" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Steals', e)} title="Steals">ST
                          {this.state.sortBy === "Steals" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('BlockedShots', e)} title="Blocked Shots">BS
                          {this.state.sortBy === "BlockedShots" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Turnovers', e)} title="Turnovers">TO
                          {this.state.sortBy === "Turnovers" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('Points', e)} title="Points">P
                          {this.state.sortBy === "Points" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('TrueShootingAttempts', e)} title="True Shooting Attempts">TSA
                          {this.state.sortBy === "TrueShootingAttempts" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" className="text-blue" onClick={(e) => this.handleSorting('TrueShootingPercentage', e)} title="True Shooting Percentage">TSP
                          {this.state.sortBy === "TrueShootingPercentage" ?
                              <div className="">
                                {this.state.sortType === "desc" ?
                                  <i class="fas fa-sort-up"></i>
                                  :
                                  <i class="fas fa-sort-down"></i>
                                }
                              </div>
                              :
                              <div className="">
                                <i class="fas fa-sort-up opacity-50"></i>
                                <i class="fas fa-sort-down opacity-50"></i>
                              </div>
                            }
                          </th>
                          <th scope="col" title="Injury Status">IS</th>
                          <th scope="col"  style={{ minWidth: "200px" }} title="Injury Notes">IN</th>
                          <th scope="col" style={{ minWidth: "150px" }}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.playerDataForShow !== undefined && this.state.playerDataForShow !== null && this.state.playerDataForShow.length > 0) ? this.state.playerDataForShow.map((item, index) => (

                          <tr className={this.myClassName(index)}  >
                            <th scope="row" title={`${item.boomPotential === true ? "Boom Potential" : item.bustPotential === true ? "Bust Potential" : ""}`} className={`fixed-side ${item.boomPotential === true ? "text-green" : item.bustPotential === true ? "text-red" : ""}`}>{item.OperatorPlayerName}</th>
                            <td>{item.Team}</td>
                            <td>{item.FanDuelPosition !== "" ? item.FanDuelPosition : "-"}</td>
                            <td>{item.DraftKingsPosition !== "" ? item.DraftKingsPosition : "-"}</td>

                            <td>{item.YahooPosition !== "" ? item.YahooPosition : "-"}</td>
                            <td>{item.OpponentRank !== "" ? item.OpponentRank : "-"}</td>
                            <td>{item.OpponentPositionRank !== "" ? item.OpponentPositionRank : "-"}</td>
                            <td>{item.Opponent !== "" ? item.Opponent : "-"}</td>

                            <td>{item.HomeOrAway !== "" ? item.HomeOrAway : "-"}</td>
                            <td>{item.FanDuelSalary !== "" ? item.FanDuelSalary : "-"}</td>
                            <td>{item.DraftKingsSalary !== "" ? item.DraftKingsSalary : "-"}</td>
                            <td>{item.YahooSalary !== "" ? item.YahooSalary : "-"}</td>
                            <td>{item.LineupStatus !== "" ? item.LineupStatus : "-"}</td>

                            <td>{item.Minutes !== "" ? item.Minutes : "-"}</td>

                            <td>{item.FieldGoalsMade}</td>
                            <td>{item.FieldGoalsAttempted !== "" ? item.FieldGoalsAttempted : "-"}</td>
                            
                            <td>{item.FantasyPointsFanDuel !== "" ? item.FantasyPointsFanDuel : "-"}</td>
                            <td>{item.FantasyPointsDraftKings !== "" ? item.FantasyPointsDraftKings : "-"}</td>

                            <td>{item.FantasyPointsYahoo !== "" ? item.FantasyPointsYahoo : "-"}</td>

                            <td>{item.FieldGoalsPercentage !== "" ? item.FieldGoalsPercentage : "-"}</td>
                            <td>{item.ThreePointersMade !== "" ? item.ThreePointersMade : "-"}</td>
                            <td>{item.ThreePointersAttempted !== "" ? item.ThreePointersAttempted : "-"}</td>
                            <td>{item.ThreePointersPercentage !== "" ? item.ThreePointersPercentage : "-"}</td>
                            <td>{item.FreeThrowsAttempted !== "" ? item.FreeThrowsAttempted : "-"}</td>
                            <td>{item.FreeThrowsPercentage !== "" ? item.FreeThrowsPercentage : "-"}</td>
                            <td>{item.Rebounds !== "" ? item.Rebounds : "-"}</td>
                            <td>{item.Assists !== "" ? item.Assists : "-"}</td>
                            <td>{item.Steals !== "" ? item.Steals : "-"}</td>
                            <td>{item.BlockedShots !== "" ? item.BlockedShots : "-"}</td>
                            <td>{item.Turnovers !== "" ? item.Turnovers : "-"}</td>
                            <td>{item.Points !== "" ? item.Points : "-"}</td>
                            <td>{item.TrueShootingAttempts !== "" ? item.TrueShootingAttempts : "-"}</td>
                            <td>{item.TrueShootingPercentage !== "" ? item.TrueShootingPercentage : "-"}</td>
                            {/* <td>{item.PlusMinus !== "" ? item.PlusMinus : "-"}</td> */}
                            {/* <td>{item.DoubleDoubles !== "" ? item.DoubleDoubles : "-"}</td> */}
                            {/* <td>{item.TripleDoubles !== "" ? item.TripleDoubles : "-"}</td> */}

                            <td>{item.InjuryStatus !== "" && item.InjuryStatus !== null ? item.InjuryStatus : "-"}</td>
                            <td>
                              {item.InjuryNotes !== null && item.InjuryNotes !== undefined && item.InjuryNotes !== "" ?
                                <ReactReadMoreReadLess
                                  charLimit={10}
                                  readMoreText={"Read more ▼"}
                                  readLessText={"Read less ▲"}
                                >
                                  {item.InjuryNotes}
                                </ReactReadMoreReadLess>
                                : "-"
                              }
                            </td>
                            <td>{moment(item.OperatorStartTime).format('MMM D, YYYY')}</td>
                          </tr>
                        ))
                          : ""
                        }
                      </tbody>
                    </table>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {

  const { loading, playerList } = state.rootReducer.playerListByTeam;
  // const { loadingdfsSlate, dfsSlateByDateList } = state.rootReducer.dfsSlateByDateReducer;
  const { loadingBoxScore, boxScoreByDateList } = state.rootReducer.boxScoreByDateReducer;
  const { loadingProjectedPlayerSlat, projectedPlayerSlatByDateList } = state.rootReducer.projectedPlayerSlatByDateReducer;

  return {
    loading,
    playerList,
    // loadingdfsSlate,
    // dfsSlateByDateList,
    loadingBoxScore,
    boxScoreByDateList,
    loadingProjectedPlayerSlat,
    projectedPlayerSlatByDateList
  };
}

export default connect(mapStateToProps)(PlayersList);