export const APIURL = "https://api.sportsdata.io/v3/nba/";

export const KEY = "8f27d3676e82471dad14fe2ba49ef2c7";

export const PROJECTION_KEY = "c8db46bcba294f148e31519c5da74c47";

// export const BACKEND_URL = "http://localhost:3003/";


// FOR DEV SERVER CRIDENTIALS

// export const BACKEND_URL = "https://devapi-sazger.imenso.in/";
// export const PAYWHIRL_LINK = "https://imenso-software.paywhirl.com/";


// FOR CLIENT SERVER CRIDENTIALS
// export const BACKEND_URL = "https://api-sazger.imenso.in/";
// export const PAYWHIRL_LINK = "https://imenso-software.paywhirl.com/";

// FOR PRODUCTION SERVER CRIDENTIALS
export const BACKEND_URL = "https://api.fantasyball.io/";
export const PAYWHIRL_LINK = "https://fantasyball.paywhirl.com/";


