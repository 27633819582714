import React, { Component } from "react";
import { boxScoreByDateActions, projectedPlayerSlatByDateActions } from "../../actions";
import { connect } from "react-redux";
import Loader from '../../assets/img/ball-loader.gif'
import moment from 'moment';
import { decode } from "querystring";


class LineUps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingProjectedPlayerSlat: false,
            projectedPlayerSlatByDateList: [],
            activeTab: 'DraftKings',
            loadingBoxScore: true,
            boxScoreByDateList: [],
            getAllPlayerData: [],
            remainingDraftkingsSalary: 50000,
            remainingFanduelSalary: 60000,
            remainingYahooSalary: 200,
            totalDraftkingsSalary: 50000,
            totalFanduelSalary: 60000,
            totalYahooSalary: 200,
            salaryRemaining: 0,
            totalFantasyPointsDraftKings: 0.0,
            totalFantasyPointsFanDuel: 0.0,
            totalFantasyPointsYahoo: 0.0,
            draftkingsLinePosition: ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'U'],
            fanduelLinePosition: ['PG', 'PG', 'SG', 'SG', 'SF', 'SF', 'PF', 'PF', 'C'],
            yahooLinePosition: ['PG', 'SG', 'G', 'SF', 'PF', 'F', 'C', 'U'],
            draftkingsOptimizeLines: [],
            fanduelOptimizeLines: [],
            yahooOptimizeLines: [],
            isOptimize: false,
            currentPositons: ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'U'],
            currentPositonLines: [],
            activeLine: "1",
            isLoading: false,
            collectIdInUsed: [],
            collectIdInUsedMain: [],
            noRecordInTopPerformer: false,
        };
        this.handleTabClick = this.handleTabClick.bind(this);
        this.handleLineClick = this.handleLineClick.bind(this);
        this.getBoxScoreByDateList = this.getBoxScoreByDateList.bind(this);
        this.handleOptimizationClick = this.handleOptimizationClick.bind(this);
    }
    handleTabClick(e) {
        console.log(moment.unix(1675555200).format("MM/DD/YYYY"));
        var type = e.target.name
        var currentPositons = this.state.draftkingsLinePosition;
        if (type === "FanDuel") {
            currentPositons = this.state.fanduelLinePosition
        }
        else if (type === "Yahoo") {
            currentPositons = this.state.yahooLinePosition
        }
        this.setState({
            activeTab: type,
            currentPositons: currentPositons,
            activeLine: "1",
            isOptimize: false,
            remainingDraftkingsSalary: this.state.totalDraftkingsSalary,
            remainingFanduelSalary: this.state.totalFanduelSalary,
            remainingYahooSalary: this.state.totalYahooSalary,
            totalFantasyPointsDraftKings: 0.0,
            totalFantasyPointsFanDuel: 0.0,
            totalFantasyPointsYahoo: 0.0,
            currentPositonLines: [],
        }, () => {
            // console.log('handleTabClick ', this.state);
        });
    }

    handleLineClick(e) {
        var type = e.target.name
        this.setState({
            activeLine: type,
        }, () => {
            if (this.state.isOptimize === true) {
                this.getTotalSalaryAndPoint();
            }
        });
    }

    getBoxScoreByDateList() {
        // this.props.dispatch(projectedPlayerSlatByDateActions.getAllProjectedPlayerSlatByDate());
        this.props.dispatch(projectedPlayerSlatByDateActions.getAllProjectedPlayerSlatByDateForLineUp());
    }
    getAllPlayerData() {

        var dataFiltered = this.state.boxScoreByDateList.filter(regData =>

            (regData.FantasyPointsFanDuel.toString().split(".")[0] !== "0"
                && regData.FantasyPointsDraftKings.toString().split(".")[0] !== "0"
                && regData.FantasyPointsYahoo.toString().split(".")[0] !== "0"
            )
        );
        this.setState({
            getAllPlayerData: dataFiltered,
        }, () => {
        })
        // console.log('playerData11 ', dataFiltered);
    }

    handleOptimizationClick() {
        console.log("handleOptimizationClick ", this.state);
        if (this.state.getAllPlayerData.length > 0) {
            if (this.state.activeTab === 'DraftKings') {
                // console.log(this.getOptimizeData("DraftKings"));
                this.setState({
                    isLoading: true,
                }, () => {
                    setTimeout(
                        () => {
                            const lineArray = this.getOptimizeData("DraftKings");
                            this.setState({
                                draftkingsOptimizeLines: lineArray,
                                currentPositonLines: lineArray,
                                yahooOptimizeLines: [],
                                fanduelOptimizeLines: [],
                                isOptimize: true,
                                isLoading: false
                            }, () => {
                                console.log('ee1 ', this.state);
                                this.getTotalSalaryAndPoint();
                            })
                        }
                        , 100);
                });

            }
            if (this.state.activeTab === 'FanDuel') {
                this.setState({
                    isLoading: true,
                }, () => {
                    setTimeout(
                        () => {
                            var lineArray = this.getOptimizeData("FanDuel");
                            this.setState({
                                fanduelOptimizeLines: lineArray,
                                currentPositonLines: lineArray,
                                draftkingsOptimizeLines: [],
                                yahooOptimizeLines: [],
                                isOptimize: true,
                                isLoading: false,
                            }, () => {
                                console.log('ee2 ', this.state);
                                this.getTotalSalaryAndPoint();
                            })
                        }
                        , 100);
                });
            }
            if (this.state.activeTab === 'Yahoo') {
                this.setState({
                    isLoading: true,
                }, () => {
                    setTimeout(
                        () => {
                            var lineArray = this.getOptimizeData("Yahoo");
                            this.setState({
                                yahooOptimizeLines: lineArray,
                                currentPositonLines: lineArray,
                                draftkingsOptimizeLines: [],
                                fanduelOptimizeLines: [],
                                isOptimize: true,
                                isLoading: false,
                            }, () => {
                                console.log('ee3 ', this.state);
                                this.getTotalSalaryAndPoint();
                            })
                        }
                        , 100);
                });
            }
        }
        else {
            // this.setState({
            //     isOptimize: false,
            //     isLoading: false
            // });

            this.setState({
                isLoading: true,
            }, () => {
                setTimeout(
                    () => {
                        this.setState({
                            isOptimize: false,
                            isLoading: false,
                            noRecordInTopPerformer: true,
                        }, () => {
                            console.log('ee3 ', this.state);
                        })
                    }
                    , 100);
            });
        }
    }

    getOptimizeData(positionType) {
        let playerData = this.state.getAllPlayerData;

        var lineArray = [];
        var lineArrayWithTotalFP = [];
        var lineCount = 3;
        var collectIdInUsed1 = [];
        // var totalFpArrayList =[];
        if (this.state.collectIdInUsedMain.length > 0) {

            collectIdInUsed1 = collectIdInUsed1.concat(this.state.collectIdInUsedMain);
        }
        // let jj = playerData.filter(e => !collectIdInUsed1.includes(e.StatID));
        // console.log('initialy ',collectIdInUsed1);
        var getLineids = [];
        let fantasyPoints = "FantasyPoints" + positionType;
        let salary = positionType + "Salary";

        // let count 

        for (var i = 1; i <= lineCount; i++) {
            var line = [];
            this.state.currentPositons.map(data => {
                // console.log("data ", data);
                var maxIndex = '';
                var dd = [];

                playerData.find(function (el, index) {
                    var position = positionType + "Position";
                    var obj = {};
                    var tt = 0;
                    if (el.position === data) {
                        tt = 1;
                    }
                    else if (data === "U") {
                        tt = 1;
                    }
                    else if (el.position !== data) {
                        var tt = el[position];
                        if (tt !== null) {
                            if (tt.includes(data)) {
                                tt = 1;
                            }
                            else {
                            }
                        }
                    }
                    if (tt == 1) {
                        dd.push(el);
                    }

                });
                if (dd.length > 0) {
                    // let max = dd.reduce(function (prev, current, index) {
                    //     if (prev[fantasyPoints] > current[fantasyPoints]) {
                    //         // console.log('Current ', prev[fantasyPoints] +" > " + current[fantasyPoints] +" && " + prev[salary] + " < "+ current[salary] +" && "+ prev[fantasyPoints]+' > ' +0)
                    //         return prev;
                    //     } else {
                    //         // console.log('Prev ', prev[fantasyPoints] +" < " + current[fantasyPoints] +" && " + prev[salary] + " > "+ current[salary] +" && "+ prev[fantasyPoints]+' > ' +0)
                    //         return current;
                    //     }
                    // })

                    var max = dd[Math.floor(Math.random() * dd.length)]


                    line.push(max);
                    // collectIdInUsed1.push(max.StatID);
                    // playerData = playerData.filter(e => !collectIdInUsed1.includes(e.StatID))
                }
            });
            if (line.length === 0) {
                this.setState({
                    collectIdInUsed: [],
                    collectIdInUsedMain: []
                }, () => {
                    // console.log(this.state);
                });
            }

            var valueArr = line.map(function (item) { return item.StatID });
            var isDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) != idx
            });


            var salarySum = line.map(item => item[salary]).reduce((prev, next) => prev + next);
            // var fantasyPointsSum = line.map(item => item[fantasyPoints]).reduce((prev, next) => prev + next);
            // console.log(fantasyPointsSum);
            // totalFpArrayList.push(fantasyPointsSum);
            var totalSal = this.state.totalDraftkingsSalary;
            if (positionType === "FanDuel") {
                totalSal = this.state.totalFanduelSalary;
            }
            else if (positionType === "Yahoo") {
                totalSal = this.state.totalYahooSalary;
            }

            if (isDuplicate) {
                i--;
            }
            else if (this.state.collectIdInUsedMain.length === 0 && totalSal - salarySum !== 0) {
                i--;
            }

            else if (totalSal - salarySum < 0) {
                --i;
            }
            else {
                // console.log("isDuplicate "+i+" ", isDuplicate);
                var result = line.map(function (a) { return a.StatID; });
                getLineids.push(result);

                lineArray.push(line);
                var tt = line.reduce((n, { FantasyPointsDraftKings }) => n + FantasyPointsDraftKings, 0)
                if (positionType === "FanDuel") {
                    tt = line.reduce((n, { FantasyPointsFanDuel }) => n + FantasyPointsFanDuel, 0)
                }
                else if (positionType === "Yahoo") {
                    tt = line.reduce((n, { FantasyPointsYahoo }) => n + FantasyPointsYahoo, 0)
                }


                lineArrayWithTotalFP.push({ 'totalFP': tt, 'line': line, 'lineNo': i });
            }


            // console.log("sum "+i+" ", line.map(item => item[salary]).reduce((prev, next) => prev + next));
        }
        console.log("WithOut sort ", lineArrayWithTotalFP);
        //    console.log("With sort ",lineArrayWithTotalFP.sort((a, b) => parseFloat(b.totalFP) - parseFloat(a.totalFP)));
        // return lineArray;
        var arrayFinal = [];
        var ass = lineArrayWithTotalFP.sort((a, b) => parseFloat(b.totalFP) - parseFloat(a.totalFP));
        ass.map(data => {
            // console.log(data);
            arrayFinal.push(data.line);
        });
        // console.log(totalFpArrayList);
        // var topValues = totalFpArrayList.sort((a,b) => b-a).slice(0,30);
        // console.log(topValues); // [789,689,98,65,33]
        // console.log(lineArray);
        return arrayFinal;
    }

    getTotalSalaryAndPoint() {
        var remainingSalary = 0;
        var totalFanDuel = this.state.totalFanduelSalary;
        var totalYahoo = this.state.totalYahooSalary;
        var totalDraft = this.state.totalDraftkingsSalary;

        var totalFantasyPointsFanDuel = 0.0;
        var totalFantasyPointsDraftKings = 0.0;
        var totalFantasyPointsYahoo = 0.0;

        if (this.state.currentPositonLines.length > 0) {
            this.state.currentPositonLines[this.state.activeLine - 1].map(pos => {
                if (this.state.activeTab === "FanDuel") {
                    // console.log('FanDuelSalary ', pos.FanDuelSalary);
                    totalFanDuel -= pos.FanDuelSalary;
                    totalFantasyPointsFanDuel += pos.FantasyPointsFanDuel;

                }
                else if (this.state.activeTab === "Yahoo") {
                    // console.log('YahooSalary ', pos.YahooSalary);
                    totalYahoo -= pos.YahooSalary;
                    totalFantasyPointsYahoo += pos.FantasyPointsYahoo;
                }
                else {
                    // console.log('draftkings salary ', pos.DraftKingsSalary);
                    totalDraft -= pos.DraftKingsSalary;
                    totalFantasyPointsDraftKings += pos.FantasyPointsDraftKings;
                }
            })
        }
        this.setState({
            remainingFanduelSalary: totalFanDuel,
            remainingDraftkingsSalary: totalDraft,
            remainingYahooSalary: totalYahoo,
            totalFantasyPointsDraftKings: totalFantasyPointsDraftKings,
            totalFantasyPointsFanDuel: totalFantasyPointsFanDuel,
            totalFantasyPointsYahoo: totalFantasyPointsYahoo
        })

    }


    handleResetClick() {
        this.setState({
            activeLine: "1",
            isOptimize: false,
            remainingDraftkingsSalary: this.state.totalDraftkingsSalary,
            remainingFanduelSalary: this.state.totalFanduelSalary,
            remainingYahooSalary: this.state.totalYahooSalary,
            totalFantasyPointsDraftKings: 0.0,
            totalFantasyPointsFanDuel: 0.0,
            totalFantasyPointsYahoo: 0.0,
            currentPositonLines: [],
            noRecordInTopPerformer: false,
        }, () => {
        });
    }


    componentDidMount() {
        this.getBoxScoreByDateList();
    }
    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        console.log("nextProps1111 LineUp ", nextProps);

        if (nextProps.loadingProjectedPlayerSlat !== prevProps.loadingProjectedPlayerSlat) {
            this.setState({
                loadingBoxScore: nextProps.loadingProjectedPlayerSlat
            });
        }

        if (nextProps.projectedPlayerSlatByDateList !== prevProps.projectedPlayerSlatByDateList) {
            if (nextProps.projectedPlayerSlatByDateList !== undefined && nextProps.projectedPlayerSlatByDateList !== null) {
                this.setState({
                    boxScoreByDateList: nextProps.projectedPlayerSlatByDateList,
                    // getAllPlayerData: nextProps.projectedPlayerSlatByDateList
                }, () => {
                    this.getAllPlayerData();
                });
            }
        }


    }
    render() {

        return (
            <div class="bestperformance bg-naviblue card py-3 px-3 mt-4 mt-lg-0">
                <h4 class="fw-bold mb-4 text-white">Line Up</h4>
                <div class="d-flex text-yellow justify-content-lg-between mb-4 platform-tab-container">
                    <ul className="nav nav-pills justify-content-lg-between d-flex w-100">
                        <li className="nav-iteam">
                            <a className={`nav-link ${this.state.activeTab === "DraftKings" ? "active" : ""}`} name="DraftKings" onClick={this.handleTabClick}>DraftKings</a>
                        </li>
                        <li className="nav-iteam">
                            <a className={`nav-link ${this.state.activeTab === "FanDuel" ? "active" : ""}`} name="FanDuel" onClick={this.handleTabClick}>FanDuel</a>
                        </li>
                        <li className="nav-iteam">
                            <a className={`nav-link ${this.state.activeTab === "Yahoo" ? "active" : ""}`} name="Yahoo" onClick={this.handleTabClick}>Yahoo</a>
                        </li>
                    </ul>
                </div>
                <div className="p-2 bg-black mb-3 rounded text-center">
                    <div className="row m-0">
                        <div className="col-6 p-0">
                            <p className="small mb-0">Salary Rem.</p>
                            <h5 className="mb-0">
                                ${
                                    this.state.activeTab === "FanDuel"
                                        ? this.state.remainingFanduelSalary
                                        : this.state.activeTab === "Yahoo"
                                            ? this.state.remainingYahooSalary
                                            : this.state.remainingDraftkingsSalary
                                }
                            </h5>
                        </div>
                        <div className="col-6 p-0">
                            <p className="small mb-0">FP Proj.</p>
                            <h5 className="mb-0">
                                {
                                    this.state.activeTab === "FanDuel"
                                        ? this.state.totalFantasyPointsFanDuel.toFixed(2)
                                        : this.state.activeTab === "Yahoo"
                                            ? this.state.totalFantasyPointsYahoo.toFixed(2)
                                            : this.state.totalFantasyPointsDraftKings.toFixed(2)
                                }
                            </h5>
                        </div>
                        {/* <div className="col-4 p-0">
                            <p className="small mb-0">Value Proj.</p>
                            <h5 className="mb-0">5.2x</h5>
                        </div> */}
                    </div>
                </div>
                <ul class="nav nav-pills line-tab justify-content-ld-between d-flex">
                    <li class="nav-item">
                        <a class={`nav-link text-white ${this.state.activeLine === "1" ? "active" : ""}`} name="1" onClick={this.handleLineClick} aria-current="page" >Line1</a>
                    </li>
                    <li class="nav-item">
                        <a class={`nav-link text-white ${this.state.isOptimize === false ? "disabled" : ""} ${this.state.activeLine === "2" ? "active" : ""}`} name="2" onClick={this.handleLineClick} aria-current="page" >Line2</a>
                    </li>
                    <li class="nav-item">
                        <a class={`nav-link text-white ${this.state.isOptimize === false ? "disabled" : ""} ${this.state.activeLine === "3" ? "active" : ""}`} name="3" onClick={this.handleLineClick} aria-current="page" >Line3</a>
                    </li>
                </ul>
                <div className="scroll scrollcolor my-4">
                    {
                    //     this.state.noRecordInTopPerformer && !this.state.isOptimize
                    //     ? <div class="list">
                    //     <div class="d-flex align-items-center">
                    //         {/* <span class="name"></span> */}
                    //         <div className="row_sidebar_stats">
                    //         No Record Found
                    //             {/* <span className="d-flex align-items-center h-100"> POSITION</span> */}
                    //         </div>
                    //     </div>
                    // </div>
                    // :
                        !this.state.isOptimize && !this.state.isLoading 
                            
                            ? this.state.currentPositons.map((position, indexPos) => (
                                <div class="list">
                                    <div class="d-flex align-items-center">
                                        <span class="name">{position}</span>
                                        <div className="row_sidebar_stats">
                                            <span className="d-flex align-items-center h-100"> POSITION</span>
                                        </div>
                                    </div>
                                </div>
                            ))

                            : this.state.isLoading
                                ?
                                <div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="d-flex">
                                            <span class="lineup-placeholder-content me-2" style={{ width: "2em", height: "29px" }}></span>
                                            <div className="row_sidebar_stats">
                                                <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : this.state.currentPositonLines.length > 0 && this.state.currentPositonLines[this.state.activeLine - 1].map((positionLine, indexLine) => (
                                    <div class="list">
                                        <div class="d-flex align-items-center">
                                            {/* <span class="name">PG
                                        </span> */}
                                            <div className="row_sidebar_stats">
                                                <div className="row">
                                                    <div className="col-8 pe-0">
                                                        <span class="playerWindowTrigger" >{positionLine.Name} </span>
                                                        <span class="d-block">
                                                            <span class="">
                                                                {
                                                                    this.state.activeTab === "FanDuel"
                                                                        ? positionLine.FanDuelPosition
                                                                        : this.state.activeTab === "Yahoo"
                                                                            ? positionLine.YahooPosition
                                                                            : positionLine.DraftKingsPosition
                                                                }
                                                            </span>
                                                            <span class=""> · <span class="supdollar">$</span>
                                                                {
                                                                    this.state.activeTab === "FanDuel"
                                                                        ? positionLine.FanDuelSalary
                                                                        : this.state.activeTab === "Yahoo"
                                                                            ? positionLine.YahooSalary
                                                                            : positionLine.DraftKingsSalary
                                                                }
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="col text-end">
                                                        <span>
                                                            {
                                                                this.state.activeTab === "FanDuel"
                                                                    ? positionLine.FantasyPointsFanDuel
                                                                    : this.state.activeTab === "Yahoo"
                                                                        ? positionLine.FantasyPointsYahoo
                                                                        : positionLine.FantasyPointsDraftKings
                                                            } FP
                                                </span>
                                                    </div>
                                                    {/* <div className="col text-right">
                                                    <span>5.1x</span>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))



                    }


                    {/* <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                               <div className="row">
                                    <div className="col-5 pe-0">
                                        <span class="playerWindowTrigger" >D. Murray </span>
                                        <span class="d-block">
                                            <span class="">SG/PG</span>
                                            <span class=""> · <span class="supdollar">$</span>8.4K</span>
                                        </span>
                                    </div>
                                    <div className="col text-right">
                                        <span>42.8FP</span>
                                    </div>
                                    <div className="col text-right">
                                        <span>5.1x</span>
                                    </div>
                               </div>
                            </div>
                        </div>  
                    </div>  */}

                    {/* <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                                <span className="d-flex align-items-center h-100"> MAKE A PICK </span>
                            </div>
                        </div>
                    </div> */}


                    {/* <div class="list">
                        <div class="d-flex align-items-center">
                            <span class="name">PG</span>
                            <div className="row_sidebar_stats">
                               <div className="row">
                                    <div className="col-5 pe-0">
                                        <span class="playerWindowTrigger" >D. Murray </span>
                                        <span class="d-block">
                                            <span class="">SG/PG</span>
                                            <span class=""> · <span class="supdollar">$</span>8.4K</span>
                                        </span>
                                    </div>
                                    <div className="col text-right">
                                        <span>42.8FP</span>
                                    </div>
                                    <div className="col text-right">
                                        <span>5.1x</span>
                                    </div>
                               </div>
                            </div>
                        </div>  
                    </div>  */}
                </div>
                {/* <div className="scroll scrollcolor my-4">
                    <div class="list">
                        <div class="d-flex">
                            <span class="lineup-placeholder-content me-2" style={{width: "2em", height:"29px"}}></span>
                            <div className="row_sidebar_stats">
                            <span className="d-flex align-items-center lineup-placeholder-content"></span>
                                <span className="d-flex align-items-center lineup-placeholder-content mt-2"></span>
                            </div>
                        </div>
                    </div>
                </div>     */}
                <div className="optimize-filter">
                    {
                        !this.state.isLoading
                            ?
                            <button className={`btn-yellow me-2 text-black`} onClick={this.handleOptimizationClick}>OPTIMIZE</button>
                            :
                            <button className={`btn-yellow me-2 text-black`} disabled="true">OPTIMIZE ....</button>
                    }
                    {
                        !this.state.isLoading
                            ?
                            <button className="btn-naviblue" onClick={() => this.handleResetClick()}>Reset</button>
                            :
                            <button className="btn-naviblue" disabled="true">Reset</button>
                    }


                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    // console.log("state ", state);
    // const { loadingBoxScore, boxScoreByDateList } = state.rootReducer.boxScoreByDateReducer;
    const { loadingProjectedPlayerSlat, projectedPlayerSlatByDateList } = state.rootReducer.projectedPlayerSlatByDateReducer;

    return {
        loadingProjectedPlayerSlat,
        projectedPlayerSlatByDateList,
    };
}

export default connect(mapStateToProps)(LineUps);