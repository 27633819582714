import { combineReducers } from "redux";
import { seasons } from "./season.reducer";
import { teamBoxScore } from "./teamBoxScore.reducer";
import { playerBoxScore } from "./playerBoxScore.reducer";
import { playersAdvancedSeasonTotals } from "./playersAdvancedSeasonTotals.reducer";
import { regSessionBoxScore } from "./regSessionBoxScore.reducer";

import { allTeamsReducer } from "./allTeams.reducer";
 
import { playerListByTeam } from "./playerListByTeam.reducer";

import { dfsSlateByDateReducer } from "./PlayerList/dfsSlatesByDate.reducer";
import { boxScoreByDateReducer } from "./PlayerList/boxScoreByDate.reducer";
import { projectedPlayerSlatByDateReducer } from "./PlayerList/projectedPlayerSlatByDate.reducer";

import { authentication } from "./auth.reducer";

import { users } from "./customer.reducer";

const rootReducer = combineReducers({
    seasons,
    teamBoxScore,
    playerBoxScore,
    playersAdvancedSeasonTotals,
    regSessionBoxScore,
    playerListByTeam,
    allTeamsReducer,
    dfsSlateByDateReducer,
    boxScoreByDateReducer,
    projectedPlayerSlatByDateReducer,
    users,
    authentication
});

export default rootReducer;