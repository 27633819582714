import { APIURL, KEY } from "../constants/config";
import moment from 'moment';

export const teamBoxScoreService = {
    getTeams,
    getTeamBoxScore
};

async function getTeams() {
    var teamsList = [];
    for(var i=1; i<=30; i++) {
        var date = moment().subtract(i, 'days').format('DD/MM/YYYY');
        var dateArr = date.split("/");
        var data = await this.getTeamBoxScore(dateArr[0], dateArr[1], dateArr[2]).then(teamData => {
            return teamData
        });

        teamsList = teamsList.concat(data); 
    }

    // var date = moment().subtract(1, 'days').format('DD/MM/YYYY');
    // var dateArr = date.split("/");  
    // teamsList = await this.getTeamBoxScore(dateArr[0], dateArr[1], dateArr[2]);


    // console.log("getTeams are ", teamsList);
    return teamsList;
}

async function getTeamBoxScore(day, month, year) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };
    const date = year+"-"+month+"-"+day;
    return fetch(APIURL+"stats/json/BoxScores/"+date+"?key="+KEY, requestOptions).then(handleResponse)
    // return fetch(APIURL+"team_box_scores/"+day+"/"+month+"/"+year, requestOptions).then(handleResponse)
        .then(teamsList => {
            // console.log("teamsList 123  ", teamsList);
            return teamsList;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}