import { APIURL, PROJECTION_KEY } from "../../constants/config";
import moment from 'moment';

export const projectedPlayerSlatByDateServices = {
    getAllProjectedPlayer,
    getProjectedPlayerApiCall,
    getProjectedPlayerForLineUp,
    getProjectedPlayerApiCallForLineUp
};

async function getAllProjectedPlayer(date1) {
    // console.log("22222 ", moment(date1).format('DD/MM/YYYY'));
    // var date = moment(date).subtract(10, 'days').format('DD/MM/YYYY');
    var date = moment(date1).format('DD/MM/YYYY');
    var dateArr = date.split("/");
    var fullDate = dateArr[2]+"-"+dateArr[1]+"-"+dateArr[0];
    var projectedPlayerSlatByDateList = await this.getProjectedPlayerApiCall(fullDate);
    return projectedPlayerSlatByDateList;
}



function getProjectedPlayerApiCall(seasonYear) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };

    return fetch(APIURL+"projections/json/PlayerGameProjectionStatsByDate/"+seasonYear+"?key="+PROJECTION_KEY, requestOptions).then(handleResponse)
        .then(projectedPlayerSlatByDateList => {
            // console.log("Get Projected Player Slat By Date Service  ", projectedPlayerSlatByDateList);
            return projectedPlayerSlatByDateList;
        });
}


// Code For LineUp
async function getProjectedPlayerForLineUp() {
    // var date = moment().subtract(10, 'days').format('DD/MM/YYYY');
    var date = moment().format('DD/MM/YYYY');
    var dateArr = date.split("/");
    var fullDate = dateArr[2]+"-"+dateArr[1]+"-"+dateArr[0];
    var projectedPlayerSlatByDateList = await this.getProjectedPlayerApiCallForLineUp(fullDate);
    return projectedPlayerSlatByDateList;
}

function getProjectedPlayerApiCallForLineUp(seasonYear) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };

    return fetch(APIURL+"projections/json/PlayerGameProjectionStatsByDate/"+seasonYear+"?key="+PROJECTION_KEY, requestOptions).then(handleResponse)
        .then(projectedPlayerSlatByDateList => {
            // console.log("Get Projected Player Slat By Date Service  ", projectedPlayerSlatByDateList);
            return projectedPlayerSlatByDateList;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}