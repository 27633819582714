import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { connect } from "react-redux";
import { teamBoxScoreActions, allTeamsActions, playerBoxScoreActions } from "../../actions";
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import Loader from '../../assets/img/ball-loader.gif'
import Norecord from '../../assets/img/no-record.png'
import CloseButton from '../../assets/img/times-solid.svg'
// import { isDisabled } from "@testing-library/user-event/dist/utils";

// const groupStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
// };

// const groupBadgeStyles = {
//     color: '#138EEC',
//     display: 'inline-block',
//     width: "100%",
//     fontSize: 16,
//     fontWeight: 'normal',
// };

const options = {
    
//   maintainAspectRatio: false,

    plugins: {
        title: {
            display: false,
            text: 'Chart.js Bar Chart - Stacked',
        },
        legend: {
            labels: {
              color: "#fff",  // not 'fontColor:' anymore
            }
        },
        
        
        tooltip: {
            callbacks: {
                label: function (context) {
                    
                    let label = context.dataset.data.label || '';
                    let date = context.label;
                    var data = context.dataset.data.filter(function (item) {
                        return (item.x === date);
                    })
                    label = data[0].y + " : " + data[0].label;
                    // var el = document.getElementById("barChartId");
                    // el.style.cursor = el[0] ? "pointer" : "default";
                    // console.log(el);
                    // el.style.cursor = 'pointer';

                    // if (label) {
                    //     label += ': ';
                    // }
                    // if (context.parsed.y !== null) {
                    //     label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                    // }
                    return label;
                },
                
            }
        },
    },

    responsive: true,
    scales: {
        x: {
            stacked: false,
        },
        y: {
            stacked: false,
        },
    },
};

const basicCategories = [
    { label: 'Fan Duel Salary', value: 'FanDuelSalary', type: 'basic' },
    { label: 'Draft Kings Salary', value: 'DraftKingsSalary', type: 'basic' },
    { label: 'Fantasy Data Salary', value: 'FantasyDataSalary', type: 'basic' },
    { label: 'Yahoo Salary', value: 'YahooSalary', type: 'basic' },
    { label: 'Fantasy Points', value: 'FantasyPoints', type: 'basic' },
    { label: 'Minutes', value: 'Minutes', type: 'basic' },
    { label: 'Seconds', value: 'Seconds', type: 'basic' },
    { label: "Field Goals Made", value: 'FieldGoalsMade', type: "basic" },
    { label: "Field Goals Attempted", value: 'FieldGoalsAttempted', type: "basic" },
    { label: "Field Goals Percentage", value: 'FieldGoalsPercentage', type: "basic" },
    { label: "Effective Field Goals Percentage", value: 'EffectiveFieldGoalsPercentage', type: "basic" },
    { label: "Two Pointers Made", value: 'TwoPointersMade', type: "basic" },
    { label: "Two Pointers Attempted", value: 'TwoPointersAttempted', type: "basic" },
    { label: "Two Pointers Percentage", value: 'TwoPointersPercentage', type: "basic" },
    { label: "Three Pointers Made", value: 'ThreePointersMade', type: "basic" },
    { label: "Three Pointers Attempted", value: 'ThreePointersAttempted', type: "basic" },
    { label: "Three Pointers Percentage", value: 'ThreePointersPercentage', type: "basic" },
    { label: "Free Throws Made", value: 'FreeThrowsMade', type: "basic" },
    { label: "Free Throws Attempted", value: 'FreeThrowsAttempted', type: "basic" },
    { label: "Free Throws Percentage", value: 'FreeThrowsPercentage', type: "basic" },
    { label: "Offensive Rebounds", value: 'OffensiveRebounds', type: "basic" },
    { label: "Defensive Rebounds", value: 'DefensiveRebounds', type: "basic" },
    { label: "Rebounds", value: 'Rebounds', type: "basic" },
    { label: "Offensive Rebounds Percentage", value: "OffensiveReboundsPercentage", type: "basic" },
    { label: "Defensive Rebounds Percentage", value: "DefensiveReboundsPercentage", type: "basic" },
    { label: "Total Rebounds Percentage", value: "TotalReboundsPercentage", type: "basic" },
    { label: "Assists", value: "Assists", type: "basic" },
    { label: "Steals", value: "Steals", type: "basic" },
    { label: "Blocked Shots", value: "BlockedShots", type: "basic" },
    { label: "Turnovers", value: "Turnovers", type: "basic" },
    { label: "Personal Fouls", value: "PersonalFouls", type: "basic" },
    { label: "Points", value: "Points", type: "basic" },
    { label: "True Shooting Attempts", value: "TrueShootingAttempts", type: "basic" },
    { label: "True Shooting Percentage", value: "TrueShootingPercentage", type: "basic" },
    { label: "Player Efficiency Rating", value: "PlayerEfficiencyRating", type: "basic" },
    { label: "Assists Percentage", value: "AssistsPercentage", type: "basic" },
    { label: "Steals Percentage", value: "StealsPercentage", type: "basic" },
    { label: "Blocks Percentage", value: "BlocksPercentage", type: "basic" },
    { label: "Turn Overs Percentage", value: "TurnOversPercentage", type: "basic" },
    { label: "Usage Rate Percentage", value: "UsageRatePercentage", type: "basic" },
    { label: "Fantasy Points Fan Duel", value: "FantasyPointsFanDuel", type: "basic" },
    { label: "Fantasy Points Draft Kings", value: "FantasyPointsDraftKings", type: "basic" },
    { label: "Fantasy Points Yahoo", value: "FantasyPointsYahoo", type: "basic" },
    { label: "Plus Minus", value: "PlusMinus", type: "basic" },
    { label: "Double Doubles", value: "DoubleDoubles", type: "basic" },
    { label: "Triple Doubles", value: "TripleDoubles", type: "basic" },
    { label: "Fantasy Points Fantasy Draft", value: "FantasyPointsFantasyDraft", type: "basic" },
];

const advancedCategories = [
    { label: 'Assist Percentage', value: 'assist_percentage', type: 'advance' },
    { label: 'Block Percentage', value: 'block_percentage', type: 'advance' },

];

// const categories = [
//     {
//         label: "Basic Category",
//         options: basicCategories
//     },
//     {
//         label: "Advanced Category",
//         options: advancedCategories
//     }
// ];

class LeadersGrids extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            loadingTeams: true,
            loadingAllTeams: true,
            loadingPlayers: true,
            teamsList: [],
            playerListByTeam: [],
            playersList: [],
            filteredPlayers: [],
            allTeamList: [],
            teamOptions: [],
            playerOptions: [],
            selectedTeam: { label: 'Golden State Warriors', value: 'GS' },
            selectedPlayer: { label: 'Stephen Curry', value: 'Stephen Curry' },
            selectedCategory: { label: 'Fantasy Points', value: 'FantasyPoints', type: 'basic' },
            selectedCategoryType: "basic",
            selectedPlayerData: "",
            topPerformerData: [],
            secondTopPerformerData: [],
            selectedPlayerSlug: "",
            regSessionList: [],
            playersAdvancedTotalsList: [],
            categories: [],
            modalToggle: false,
            modalData: [],
            modalTitle: "",
            graphData: null,
            graphDataSets: [],
            playerDataForDropDown: [],
        };

        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend
        );

        this.getTeamBoxScore = this.getTeamBoxScore.bind(this);
        this.getAllTeamList = this.getAllTeamList.bind(this);
        this.getPlayerBoxScore = this.getPlayerBoxScore.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.chartDataShow = this.chartDataShow.bind(this);
        this.makePlayerData = this.makePlayerData.bind(this);
    }

    modalToggle() {
        this.setState({
            modalToggle: !this.state.modalToggle
        })
    }

    setSelectedCategory(e) {
        this.setState({
            selectedCategory: e,
        }, () => {
            this.makePlayerData();
        });
    }
    setSelectedTeam(e) {
        this.setState({
            selectedTeam: e,
            loadingTeams: true
        }, () => {
            this.getPlayerBoxScore();
            this.getPlayerOptions();
        })
    }

    setSelectedPlayer(e) {
        this.setState({
            selectedPlayer: e
        }, () => {
            this.makePlayerData();
        })
    }

    getTeamBoxScore() {
        this.props.dispatch(teamBoxScoreActions.getTeamBoxScore());
    }
    getAllTeamList() {
        // this.props.dispatch(allTeamsActions.getAllTeams());
    }
    // For Get All Player Of Selected Team
    getPlayerBoxScore() {
        this.props.dispatch(playerBoxScoreActions.getPlayerBoxScore(this.state.selectedTeam.value));
    }

    getTeamOptions() {
        var teamOptions = [];
        if (this.state.allTeamList !== null) {
            this.state.allTeamList.map(teams => {
                var obj = { value: teams.Key, label: teams.City + " " + teams.Name };
                var isExist = false;
                for (var i = 0; i < teamOptions.length; i++) {
                    if (teamOptions[i].value === obj.value && teamOptions[i].label === obj.label) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    teamOptions.push(obj);
                }
            });
            this.setState({
                teamOptions: teamOptions,
                selectedTeam: this.state.selectedTeam
            }, () => {
                this.getPlayerOptions();
            });
        }
    }

    getPlayerOptions() {
        var playerOptions = [];
        var defaultSelect = 0;
        // if (this.state.playersList !== null) {
            this.state.teamsList.map(allData => {
                allData.PlayerGames.map(player => {
            // this.state.playersList.map(player => {
                var playerName = player.Name;
                var obj = { value: playerName, label: playerName };
                var isExist = false;

                for (var i = 0; i < playerOptions.length; i++) {
                    if (playerOptions[i].value === obj.value && playerOptions[i].label === obj.label) {
                        isExist = true;
                        break;
                    }
                    if (playerOptions[i].value === this.state.selectedPlayer.value) {
                        defaultSelect = 1;
                    }

                }

                if (!isExist) {
                    playerOptions.push(obj);
                }

                // playerOptions.push(obj);
            // });
        });
    })
            // console.log(playerOptions);
            this.setState({
                playerOptions: playerOptions,
                selectedPlayer: defaultSelect === 1 ? this.state.selectedPlayer : playerOptions.length > 0 ? playerOptions[0] : this.state.selectedPlayer
                // selectedPlayer: this.state.selectedPlayer
                // selectedPlayer: playerOptions[0]
            }, () => {
                this.makePlayerData();
            });
       
        // }
    }

    // removeDuplicates(arr) {
    //     return arr.filter((item, 
    //         index) => arr.indexOf(item) === index);
    // }

    // getPlayerDataForDropDown() {
    //     var playerName = [];
    //     this.state.teamsList.map(allData => {
    //         allData.PlayerGames.map(player => {
    //             playerName.push(player.Name);
    //         });
    //     })

    //     return this.removeDuplicates(playerName);
    // }

    getPlayersDataFromBoxScore() {
        var playerdata = [];
        this.state.teamsList.map(allData => {
            allData.PlayerGames.map(player => {
                playerdata.push(player);
            });
        })
        // if (this.state.selectedTeam) {
        //     var selectedTeam = this.state.selectedTeam.value;
        // }
        // if (this.state.selectedPlayer) {
        //     var selectedPlayer = this.state.selectedPlayer.value;
        // }
        // if (this.state.selectedCategory) {
        //     var selectedCategory = this.state.selectedCategory.value;
        // }
        // var dd = playerdata.filter(function (item) {
        //     return (item.Team === selectedTeam);
        // })
        // return dd;

        return playerdata;

    }


    makePlayerData() {
        const tt = this.getPlayersDataFromBoxScore();
        var regSessionList = tt;
        var selectedPlayer = this.state.selectedPlayer.value;
        var selectedCategory = this.state.selectedCategory.value;
        // var selectedTeam = this.state.selectedTeam.value;
        var selectedPlayerData = [];
        var selectedPlayerLabel = "";
        var selectedPlayerStat = [];
        var playerGraphData = [];
        var graphLabels = [];
        var dateFilterArr = [];
        // var max = {};
        // var secondMax = {};
        var maxIndex = "";
        var topPerformerData = [];
        var topPerformerScore = [];
        var secondTopPerformerData = [];
        var secondTopPerformerScore = [];
        // var secondTopPerformerLabel = "";
        // var topPerformerLabel = [];
        // var topPerformerPlayerName = [];
        var topPerformerForTooltip = [];
        var secondTopPerformerForTooltip = [];
        var selectedPlayerStatForTooltip = [];

        selectedPlayerData = tt.filter(function (data) {
            if (this.count < 30 && (data.Name === selectedPlayer)) {
                this.count++;
                return true;
            }
            return false;
        },
            { count: 0 }
        );


        selectedPlayerData.map(data => {
            // if(data.Name == selectedPlayer){
            var date = new Date(data.Day);
            dateFilterArr.push(data.Day);
            selectedPlayerLabel = data.Name;
            var yLableData = date.getDate() + " " + date.toLocaleString('default', { month: 'short' });
            graphLabels.push(yLableData);
            selectedPlayerStat.push(data[selectedCategory]);
            selectedPlayerStatForTooltip.push({ 'label': data.Name, "y": data[selectedCategory], "x": yLableData });

            // }
        });

        dateFilterArr.map(data => {
            var filteredData = regSessionList.filter(regData => regData.Day === data);
            // console.log('filrerData ', filteredData);
            const max = filteredData.reduce(function (prev, current, index) {
                if (prev[selectedCategory] > current[selectedCategory]) {
                    return prev;
                } else {
                    maxIndex = index;
                    return current;
                }
                // return (prev[selectedCategory] > current[selectedCategory]) ? prev : current
            })
            // console.log('maxIndex ', maxIndex);

            filteredData = filteredData.filter(regData => regData.Name !== max.Name);
            // const ss  = filteredData.splice(maxIndex, 1);
            // console.log('slice data ', ss);
            // console.log(selectedPlayerStatForTooltip['x'] + ", " + selectedPlayerStatForTooltip['y'] + " -  " + selectedPlayerStatForTooltip['label'] + " = " + selectedTeam + ' selectedPlayer');
            if (filteredData.length > 0) {
                // console.log('Max data check ', max);
                // console.log('with Slice ', filteredData);
                const secondMax = filteredData.reduce(function (prev, current, index) {
                    if (prev[selectedCategory] > current[selectedCategory]) {
                        return prev;
                    } else {
                        // maxIndex = index;
                        return current;
                    }
                    // return (prev[selectedCategory] > current[selectedCategory]) ? prev : current
                })
                // var secondMax = filteredData.sort(function (a, b) { return b[selectedCategory] - a[selectedCategory]; })[1];
                secondTopPerformerData.push(secondMax);
                secondTopPerformerScore.push(secondMax[selectedCategory]);
                var secondDate1 = new Date(secondMax.Day);
                var secondYlable = secondDate1.getDate() + " " + secondDate1.toLocaleString('default', { month: 'short' })
                secondTopPerformerForTooltip.push({ 'label': secondMax.Name, "y": secondMax[selectedCategory], "x": secondYlable });
                // console.log(secondYlable + ", " + secondMax[selectedCategory] + " -  " + secondMax.Name + " = " + secondMax.Team + ' secondMax');
            }
            topPerformerData.push(max);
            topPerformerScore.push(max[selectedCategory]);
            var date11 = new Date(max.Day);
            var yLabel = date11.getDate() + " " + date11.toLocaleString('default', { month: 'short' })
            topPerformerForTooltip.push({ 'label': max.Name, "y": max[selectedCategory], "x": yLabel });

            // if(secondYlable=="16 Nov"){
            // console.log(yLabel + ", " + max[selectedCategory] + " -  " + max.Name + " = " + max.Team + ' Max');
            // }
        });

        playerGraphData.push({
            "label": selectedPlayerLabel,
            "data": selectedPlayerStatForTooltip,
            "backgroundColor": '#197eca',
        });

        playerGraphData.push({
            "label": "Top Performer",
            "data": topPerformerForTooltip,
            "backgroundColor": '#FFD700',
            // "test": topPerformerPlayerName
        });

        playerGraphData.push({
            "label": "Second Top Performer",
            "data": secondTopPerformerForTooltip,
            "backgroundColor": '#C0C0C0',
        });



        if (graphLabels.length !== 0) {
            graphLabels.reverse();
            this.setState({
                selectedPlayerData: selectedPlayerData,
                topPerformerData: topPerformerData,
                secondTopPerformerData: secondTopPerformerData,
                graphData: {
                    ...this.state.graphData,
                    labels: graphLabels,
                    datasets: playerGraphData
                }
            }, () => {
                // console.log(this.state.graphData);
            });
        } else {
            this.setState({
                graphData: []
            })
        }

    }

    chartDataShow(event) {
        // console.log("Chart start");
        var element = getElementAtEvent(this.chartRef.current, event);

        if (!element.length) return;

        var dataSetIndex = element[0].datasetIndex;
        var elementIndex = element[0].index;

        if (dataSetIndex === 0) {
            var modalData = [];
            var selectedPlayerData = this.state.selectedPlayerData;
            // console.log("aassss, ", selectedPlayerData[elementIndex]);

            modalData.push(selectedPlayerData[elementIndex]);

            this.setState({
                modalTitle: "Player Info",
                modalData: modalData,
                modalToggle: true
            });
        } else if (dataSetIndex === 2) {
            var modalData = [];
            var secondTopPerformerData = this.state.secondTopPerformerData;
            modalData.push(secondTopPerformerData[elementIndex]);

            this.setState({
                modalTitle: "Player Info",
                modalData: modalData,
                modalToggle: true
            });
        } else if (dataSetIndex === 1) {
            var modalData = [];
            var topPerformerData = this.state.topPerformerData;
            modalData.push(topPerformerData[elementIndex]);

            this.setState({
                modalTitle: "Player Info",
                modalData: modalData,
                modalToggle: true
            });
        }
    }

    componentDidMount() {
        this.getTeamBoxScore();
        this.getAllTeamList();
        this.getPlayerBoxScore();
        this.makePlayerData();
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        // console.log("nextProps ", nextProps);
        if (nextProps.loadingTeams !== prevProps.loadingTeams) {
            this.setState({
                loadingTeams: nextProps.loadingTeams
            });
        }

        if (nextProps.teamsList !== prevProps.teamsList) {
            if (nextProps.teamsList !== undefined && nextProps.teamsList !== null) {
                this.setState({
                    teamsList: nextProps.teamsList,
                }, () => {
                    // this.getPlayerOptions();
                    this.makePlayerData();
                })
            }
        }

        if (nextProps.loadingAllTeams !== prevProps.loadingAllTeams) {
            this.setState({
                loadingAllTeams: nextProps.loadingAllTeams
            });
        }
        if (nextProps.allTeamList !== prevProps.allTeamList) {
            if (nextProps.allTeamList !== undefined && nextProps.allTeamList !== null) {
                this.setState({
                    allTeamList: nextProps.allTeamList,
                }, () => {
                    this.getTeamOptions();
                });
            }
        }
        if (nextProps.loadingPlayers !== prevProps.loadingPlayers) {
            this.setState({
                loadingPlayers: nextProps.loadingPlayers
            });
        }
        if (nextProps.playersList !== prevProps.playersList) {
            // console.log("qw1212", nextProps.playersList);
            if (nextProps.playersList !== undefined) {
                this.setState({
                    playersList: nextProps.playersList
                }, () => {
                    this.getPlayerOptions();
                })
            }
        }

    }

    render() {
        const closeBtn = (
            <button className="close" onClick={() => this.modalToggle()} type="button">
                <img width="15" src={CloseButton} />
            </button>
        );

        return (
            <>
                <div className="graph">
                    <div className="card bg-naviblue">
                        <div className="card-body">
                            <div className="mb-4 mt-2 text-navibluelight row align-items-center">
                                <h5 className="col-12 col-sm-4 col-md-3 mb-0 text-white">Stat Manipulator</h5>
                                <div className="col-6 col-sm-4 col-md-3 my-2 my-md-0">
                                    <Select
                                        placeholder="Select Category"
                                        defaultValue={this.state.selectedCategory}
                                        value={this.state.selectedCategory}
                                        className="selectpicker w-100"
                                        onChange={(e) => this.setSelectedCategory(e)}
                                        options={basicCategories}
                                    // formatGroupLabel = {formatGroupLabel}
                                    />
                                </div>
                                {/* <div className="col-sm-3 mx-2">
                                    <Select
                                        placeholder="Select Team"
                                        defaultValue={this.state.selectedTeam}
                                        value={this.state.selectedTeam}
                                        className="selectpicker w-100"
                                        onChange={(e) => this.setSelectedTeam(e)}
                                        options={this.state.teamOptions}
                                    />
                                </div> */}
                                <div className="col-6 col-sm-4 col-md-3">
                                    <div className="position-relative Playersearch">
                                        <Select
                                            placeholder="Select Player"
                                            defaultValue={this.state.selectedPlayer}
                                            value={this.state.selectedPlayer}
                                            className="selectpicker w-100"
                                            onChange={(e) => this.setSelectedPlayer(e)}
                                            options={this.state.playerOptions}
                                        />
                                        <i class="fas fa-search"></i>
                                    </div>    
                                </div>
                            </div>
                            <div className="barChart">
                                {
                                    (this.state.loadingTeams)
                                        ? <div className="imgloaderview" style={{height:"485px"}}> <div className="text-center img-loader"> <img src={Loader} width="" height="" /> </div> </div>
                                        : (!this.state.loadingTeams && this.state.loadingPlayers)
                                            ? <div className="text-center img-loader d-flex align-items-center justify-content-center h-100"> <img src={Loader} width="" height="" /> </div>
                                            : (this.state.graphData !== null && this.state.graphData.length !== 0)
                                                ? <Bar
                                                    id="barChartId"
                                                    ref={this.chartRef}
                                                    options={options}
                                                    data={this.state.graphData}
                                                    onClick={(e) => this.chartDataShow(e)}
                                                />
                                                : <div className="text-center norecord">
                                                    <span><img width="209" src={Norecord} /></span>
                                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.modalToggle} size="lg" toggle={() => this.modalToggle()} >
                    <ModalHeader close={closeBtn} className="bg-naviblue text-yellow" toggle={() => this.modalToggle()}>{this.state.modalTitle}</ModalHeader>
                    <ModalBody className="bg-naviblue">
                        <div class="Leaders-detail">
                            {
                                this.state.selectedCategoryType === "basic"
                                    ? this.state.modalData.map(data => (
                                        <div className="row">
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">NAME : <span> {data.Name} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Date : <span> {moment(data.Day).format('D MMM, YYYY')} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Opponent : <span> {data.Opponent} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Team : <span> {data.Team} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Lineup Status : <span> {data.LineupStatus} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">FanDuel Salary : <span> {data.FanDuelSalary} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Draft Kings Salary : <span> {data.DraftKingsSalary} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Data Salary : <span> {data.FantasyDataSalary} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Yahoo Salary : <span> {data.YahooSalary} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Points : <span> {data.FantasyPoints} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Minutes : <span> {data.Minutes} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Seconds : <span> {data.Seconds} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Field Goals Made : <span> {data.FieldGoalsMade} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Field Goals Attempted : <span> {data.FieldGoalsAttempted} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Field Goals Percentage : <span> {data.FieldGoalsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Effective Field Goals Percentage : <span> {data.EffectiveFieldGoalsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Two Pointers Made : <span> {data.TwoPointersMade} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Two Pointers Attempted : <span> {data.TwoPointersAttempted} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Two Pointers Percentage : <span> {data.TwoPointersPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Three Pointers Made : <span> {data.ThreePointersMade} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Three Pointers Attempted : <span> {data.ThreePointersAttempted} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Three Pointers Percentage : <span> {data.ThreePointersPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Free Throws Made : <span> {data.FreeThrowsMade} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Free Throws Attempted : <span> {data.FreeThrowsAttempted} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Free Throws Percentage : <span> {data.FreeThrowsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Offensive Rebounds : <span> {data.OffensiveRebounds} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Defensive Rebounds : <span> {data.DefensiveRebounds} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Rebounds : <span> {data.Rebounds} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Offensive Rebounds Percentage : <span> {data.OffensiveReboundsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Defensive Rebounds Percentage : <span> {data.DefensiveReboundsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Total Rebounds Percentage : <span> {data.TotalReboundsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Assists : <span> {data.Assists} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Steals : <span> {data.Steals} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Blocked Shots : <span> {data.BlockedShots} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Turnovers : <span> {data.Turnovers} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Personal Fouls : <span> {data.PersonalFouls} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Points : <span> {data.Points} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">True Shooting Attempts : <span> {data.TrueShootingAttempts} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">True Shooting Percentage : <span> {data.TrueShootingPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Player Efficiency Rating : <span> {data.PlayerEfficiencyRating} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Assists Percentage : <span> {data.AssistsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Steals Percentage : <span> {data.StealsPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Blocks Percentage : <span> {data.BlocksPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Turn Overs Percentage : <span> {data.TurnOversPercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Usage Rate Percentage : <span> {data.UsageRatePercentage} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Points FanDuel : <span> {data.FantasyPointsFanDuel} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Points Draft Kings : <span> {data.FantasyPointsDraftKings} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Points Yahoo : <span> {data.FantasyPointsYahoo} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Plus Minus : <span> {data.PlusMinus} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Double Doubles : <span> {data.DoubleDoubles} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Triple Doubles : <span> {data.TripleDoubles} </span> </div>
                                            <div class="col-6 col-md-6 col-lg-4 mb-3">Fantasy Points Fantasy Draft : <span> {data.FantasyPointsFantasyDraft} </span> </div>
                                        </div>
                                    ))

                                    : ""
                            }
                        </div>
                    </ModalBody>
                    {/* <ModalFooter>
                    </ModalFooter> */}
                </Modal>
            </>
        );
    }
}



function mapStateToProps(state) {
    // console.log("state ", state.rootReducer.playerListByTeam);
    const { loadingTeams, teamsList } = state.rootReducer.teamBoxScore;
    const { loadingAllTeams, allTeamList } = state.rootReducer.allTeamsReducer;
    const { loadingPlayers, playersList } = state.rootReducer.playerBoxScore;

    return {
        loadingTeams,
        teamsList,
        allTeamList,
        loadingAllTeams,
        loadingPlayers,
        playersList
    };
}

export default connect(mapStateToProps)(LeadersGrids);
